import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PatientDashboard from "../Patients/Dashboard";
import PatientLogin from "../Patients/Login";
import PatientSendOtp from "../Patients/Login/SendOtp";
import PatientVerifyOtp from "../Patients/Login/VerifyOtp";
import PatientProfile from "../Patients/Profile";
import Questionnaires from "../Patients/Questionnaires";
import Timelines from "../Patients/Timelines";
import Claims from "../Providers/Claims";
import ProviderDashboard from "../Providers/Dashboard";
import ProviderProfile from "../Providers/Profile";
import { ROUTES, USER_TYPES } from "./Constants";
import AuthLayout from "./Layouts/Auth";
import SignUpLayout from "./Layouts/SignUp";
import UnauthLayout from "./Layouts/Unauth";
import Welcome from "../Welcome";
import Landing from "../Landing";
import Logout from "./Logout";
import FaqPage from "../StaticPages/FAQ";
import PublicLayout from "./Layouts/Public";
import WhatWeDoPage from "../StaticPages/WhatWeDo";
import ContactUs from "../StaticPages/ContactUs";
import ForPatients from "../StaticPages/ForPatients";
import ForProviders from "../StaticPages/ForProviders";
import PrivacyPolicy from "../StaticPages/PrivacyPolicy";
import PatientDetails from "../Providers/PatientDetails";
import VitalAlerts from "../Providers/Alerts/Vitals";
import OtherAlerts from "../Providers/Alerts/Other";
import ForgotPassword from "./Sessions/ForgotPassword";
import { useEffect } from "react";
import { setUserType } from "../../Reducers/appSlice";
import ResetPassword from "./Sessions/ResetPassword";
import Registration from "./Sessions/Resgistration";
import Videos from "../StaticPages/Videos";
import ShowVideo from "../StaticPages/Videos/show";
import ClinicAdminDashboard from "../ClinicAdmins/Dashboard";
import Providers from "../ClinicAdmins/Providers";
import ProviderActivePatients from "../Providers/Patients";
import ProviderCompletedPatients from "../Providers/Patients/Completed";
import ClinicAdminPatients from "../ClinicAdmins/Patients";
import CliniAdminCompletedPatients from "../ClinicAdmins/Patients/Completed";
import CognitiveJourney from "../StaticPages/CognitiveJourney";
import Weather from "../Patients/Weather";
import Login from "./Sessions/Login";
import SendOtp from "./Sessions/Login/SendOtp";
import VerifyOtp from "./Sessions/Login/VerifyOtp";
import RespiratoryCare from "../StaticPages/RespiratoryCare";
import ManualBook from "../ClinicAdmins/Sleep/ManualBook";
import SleepLocations from "../ClinicAdmins/Sleep/Locations";
import SleepBookings from "../ClinicAdmins/Sleep/Bookings";
import SleepStudy from "../Patients/Bookings/SleepStudy";
import Payments from "../Patients/Payments";
import PatientPrograms from "../Patients/Programs";
import Tinnitus from "../StaticPages/Tinnitus";
import PatientBookings from "../Patients/Bookings";
import PatientFotona from "../Patients/Bookings/Fotona";
import ClinicAdminFotona from "../ClinicAdmins/Fotona/Bookings";
import FotonaLocations from "../ClinicAdmins/Fotona/Locations";
import SleepDevices from "../ClinicAdmins/Sleep/Devices";
import Vials from "../Providers/Vials";
import Devices from "../Patients/Devices";
import Wellness from "../Patients/Wellness";
import Ring from "../Patients/Ring";
import General from "../Patients/General";
import Slit from "../Patients/Slit";
import Biologics from "../Patients/Biologics";
import PatientSupport from "../Patients/Support";
import ProviderSupport from "../Providers/Support";
import PrePostProcedure from "../Patients/PrePostProcedure";
import ClinicAdminSupport from "../ClinicAdmins/Support";
import Health from "../Patients/Health";
import ClinicRegistration from "../ClinicAdmins/Registration";
import Webinar from "../ClinicAdmins/Webinar";
import ClinicAdminProfile from "../ClinicAdmins/Profile";
import WentWrong from "../WentWrong";
import AccountSettings from "../ClinicAdmins/Account/Settings";
import AccountSubscriptions from "../ClinicAdmins/Account/Subscriptions";
import AccountTransactions from "../ClinicAdmins/Account/Transactions";
import AccessDenied from "../AccessDenied";
import Audiology from "../Patients/Audiology";

const Routing = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userType =
    useSelector((state) => state?.app?.userType) ||
    localStorage.getItem("userType");
  const location = useLocation();
  const dispatch = useDispatch();

  const setUserTypeEverywhere = (type) => {
    if (isLoggedIn && userType) {
      return false;
    }
    dispatch(setUserType(type));
    localStorage.removeItem("userType");
    localStorage.setItem("userType", type);
  };

  useEffect(() => {
    if (location.pathname.includes("/patient/")) {
      setUserTypeEverywhere(USER_TYPES.PATIENT);
    } else if (location.pathname.includes("/provider/")) {
      setUserTypeEverywhere(USER_TYPES.PROVIDER);
    } else if (location.pathname.includes("/clinicAdmin/")) {
      setUserTypeEverywhere(USER_TYPES.CLINIC_ADMIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const renderIfPatientLoggedIn = (Component) => {
    return isLoggedIn ? (
      userType === USER_TYPES.PATIENT ? (
        <AuthLayout>{Component}</AuthLayout>
      ) : (
        <Navigate to={ROUTES.LANDING.PATH} replace={true} />
      )
    ) : (
      <Navigate to={ROUTES.PATIENT.LOGIN.PATHS.SEND_OTP} replace={true} />
    );
  };

  const renderIfClinicAdminLoggedIn = (Component) => {
    return isLoggedIn ? (
      userType === USER_TYPES.CLINIC_ADMIN ? (
        <AuthLayout>{Component}</AuthLayout>
      ) : (
        <Navigate to={ROUTES.LANDING.PATH} replace={true} />
      )
    ) : (
      <Navigate to={ROUTES.CLINIC_ADMIN.LOGIN.PATHS.SEND_OTP} replace={true} />
    );
  };

  const renderPublicPages = (Component, CustomLayout, header) => {
    return <CustomLayout header={header}>{Component}</CustomLayout>;
  };

  const renderIfProviderLoggedIn = (Component) => {
    return isLoggedIn ? (
      userType === USER_TYPES.PROVIDER ? (
        <AuthLayout>{Component}</AuthLayout>
      ) : (
        <Navigate to={ROUTES.LANDING.PATH} replace={true} />
      )
    ) : (
      <Navigate to={ROUTES.PROVIDER.LOGIN.PATHS.EMAIL} replace={true} />
    );
  };

  const renderIfNotLoggedIn = (Component, CustomLayout) => {
    return isLoggedIn ? (
      <Navigate to={ROUTES.LANDING.PATH} replace={true} />
    ) : (
      <CustomLayout>{Component}</CustomLayout>
    );
  };

  const patientRoutes = [
    {
      path: ROUTES.PATIENT.PROGRAMS.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<PatientPrograms />),
    },
    {
      path: ROUTES.PATIENT.DASHBOARD.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<PatientDashboard />),
    },
    {
      path: ROUTES.PATIENT.WEATHER.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Weather />),
    },
    {
      path: ROUTES.PATIENT.PROFILE.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<PatientProfile />),
    },
    {
      path: ROUTES.PATIENT.DEVICES.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Devices />),
    },
    {
      path: ROUTES.PATIENT.BOOKINGS.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<PatientBookings />),
    },
    {
      path: ROUTES.PATIENT.HEALTH.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Health />),
    },
    {
      path: ROUTES.PATIENT.QUESTIONNAIRES.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Questionnaires />),
    },
    {
      path: ROUTES.PATIENT.TIMELINES.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Timelines />),
    },
    {
      path: ROUTES.PATIENT.SLEEP_STUDY.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<SleepStudy />),
    },
    {
      path: ROUTES.PATIENT.FOTONA.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<PatientFotona />),
    },
    {
      path: ROUTES.PATIENT.PAYMENTS.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Payments />),
    },
    {
      path: ROUTES.PATIENT.AUDIOLOGY.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Audiology />),
    },
    {
      path: ROUTES.PATIENT.REGISTER.PATH,
      exact: true,
      element: renderIfNotLoggedIn(<Registration />, SignUpLayout),
    },
    {
      path: ROUTES.PATIENT.LOGIN.PATHS.SEND_OTP,
      exact: true,
      element: renderIfNotLoggedIn(<PatientSendOtp />, UnauthLayout),
    },
    {
      path: ROUTES.PATIENT.LOGIN.PATHS.VERIFY_OTP,
      exact: true,
      element: renderIfNotLoggedIn(<PatientVerifyOtp />, UnauthLayout),
    },
    {
      path: ROUTES.PATIENT.LOGIN.PATHS.EMAIL,
      exact: true,
      element: renderIfNotLoggedIn(<PatientLogin />, UnauthLayout),
    },
    {
      path: ROUTES.PATIENT.FORGOT_PASSWORD.PATH,
      exact: true,
      element: renderIfNotLoggedIn(<ForgotPassword />, UnauthLayout),
    },
    {
      path: ROUTES.PATIENT.RESET_PASSWORD.PATH,
      exact: true,
      element: renderIfNotLoggedIn(<ResetPassword />, UnauthLayout),
    },
    {
      path: ROUTES.PATIENT.RING.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Ring />),
    },
    {
      path: ROUTES.PATIENT.SLIT.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Slit />),
    },
    {
      path: ROUTES.PATIENT.BIOLOGICS.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Biologics />),
    },
    {
      path: ROUTES.PATIENT.PRE_POST_PROCEDURE.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<PrePostProcedure />),
    },
    {
      path: ROUTES.PATIENT.GENERAL.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<General />),
    },
    {
      path: ROUTES.PATIENT.WELLNESS.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<Wellness />),
    },
    {
      path: ROUTES.PATIENT.SUPPORT.PATH,
      exact: true,
      element: renderIfPatientLoggedIn(<PatientSupport />),
    },
  ];

  const providerRoutes = [
    {
      path: ROUTES.PROVIDER.DASHBOARD.PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<ProviderDashboard />),
    },
    {
      path: ROUTES.PROVIDER.PROFILE.PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<ProviderProfile />),
    },
    {
      path: ROUTES.PROVIDER.ACTIVE_PATIENTS.PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<ProviderActivePatients />),
    },
    {
      path: ROUTES.PROVIDER.COMPLETED_PROGRAMS.PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<ProviderCompletedPatients />),
    },
    {
      path: ROUTES.PROVIDER.PATIENT_DETAILS.ACTUAL_PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<PatientDetails />),
    },
    {
      path: ROUTES.PROVIDER.SUPPORT.PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<ProviderSupport />),
    },
    {
      path: ROUTES.PROVIDER.VITAL_ALERTS.PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<VitalAlerts />),
    },
    {
      path: ROUTES.PROVIDER.OTHER_ALERTS.PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<OtherAlerts />),
    },
    {
      path: ROUTES.PROVIDER.CLAIMS.PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<Claims />),
    },
    {
      path: ROUTES.PROVIDER.VIALS.PATH,
      exact: true,
      element: renderIfProviderLoggedIn(<Vials />),
    },
    {
      path: ROUTES.PROVIDER.REGISTER.PATH,
      exact: true,
      element: renderIfNotLoggedIn(<Registration />, SignUpLayout),
    },
    {
      path: ROUTES.PROVIDER.LOGIN.PATHS.SEND_OTP,
      exact: true,
      element: renderIfNotLoggedIn(<SendOtp />, UnauthLayout),
    },
    {
      path: ROUTES.PROVIDER.LOGIN.PATHS.VERIFY_OTP,
      exact: true,
      element: renderIfNotLoggedIn(<VerifyOtp />, UnauthLayout),
    },
    {
      path: ROUTES.PROVIDER.LOGIN.PATHS.EMAIL,
      exact: true,
      element: renderIfNotLoggedIn(<Login />, UnauthLayout),
    },
    {
      path: ROUTES.PROVIDER.FORGOT_PASSWORD.PATH,
      exact: true,
      element: renderIfNotLoggedIn(<ForgotPassword />, UnauthLayout),
    },
    {
      path: ROUTES.PROVIDER.RESET_PASSWORD.PATH,
      exact: true,
      element: renderIfNotLoggedIn(<ResetPassword />, UnauthLayout),
    },
  ];

  const publicRoutes = [
    {
      path: ROUTES.DEFAULT.PATH,
      exact: true,
      element: <Welcome />,
    },
    {
      path: ROUTES.LANDING.PATH,
      exact: true,
      element: <Landing />,
    },
    {
      path: ROUTES.LOGOUT.PATH,
      exact: true,
      element: <Logout />,
    },
    {
      path: ROUTES.ACCESS_DENIED.PATH,
      exact: true,
      element: renderPublicPages(<AccessDenied />, SignUpLayout),
    },
    {
      path: ROUTES.WENT_WRONG.PATH,
      exact: true,
      element: renderPublicPages(<WentWrong />, SignUpLayout),
    },
    {
      path: ROUTES.PUBLIC.FAQ.PATH,
      exact: true,
      element: renderPublicPages(
        <FaqPage />,
        PublicLayout,
        ROUTES.PUBLIC.FAQ.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.WHAT_WE_DO.PATH,
      exact: true,
      element: renderPublicPages(
        <WhatWeDoPage />,
        PublicLayout,
        ROUTES.PUBLIC.WHAT_WE_DO.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.CONTACT_US.PATH,
      exact: true,
      element: renderPublicPages(
        <ContactUs />,
        PublicLayout,
        ROUTES.PUBLIC.CONTACT_US.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.FOR_PATIENTS.PATH,
      exact: true,
      element: renderPublicPages(
        <ForPatients />,
        PublicLayout,
        ROUTES.PUBLIC.FOR_PATIENTS.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.FOR_PROVIDERS.PATH,
      exact: true,
      element: renderPublicPages(
        <ForProviders />,
        PublicLayout,
        ROUTES.PUBLIC.FOR_PROVIDERS.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.COGNITIVE_JOURNEY.PATH,
      exact: true,
      element: renderPublicPages(
        <CognitiveJourney />,
        PublicLayout,
        ROUTES.PUBLIC.COGNITIVE_JOURNEY.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.PRIVACY_POLICY.PATH,
      exact: true,
      element: renderPublicPages(
        <PrivacyPolicy />,
        PublicLayout,
        ROUTES.PUBLIC.PRIVACY_POLICY.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.VIDEOS.PATH,
      exact: true,
      element: renderPublicPages(
        <Videos />,
        PublicLayout,
        ROUTES.PUBLIC.VIDEOS.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.SHOW_VIDEO.PATH,
      exact: true,
      element: renderPublicPages(
        <ShowVideo />,
        PublicLayout,
        ROUTES.PUBLIC.SHOW_VIDEO.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.RESPIRATORY_CARE.PATH,
      exact: true,
      element: renderPublicPages(
        <RespiratoryCare />,
        PublicLayout,
        ROUTES.PUBLIC.RESPIRATORY_CARE.LABEL
      ),
    },
    {
      path: ROUTES.PUBLIC.TINNITUS.PATH,
      exact: true,
      element: renderPublicPages(
        <Tinnitus />,
        PublicLayout,
        ROUTES.PUBLIC.TINNITUS.LABEL
      ),
    },
  ];

  const clinicAdminRoutes = [
    {
      path: ROUTES.CLINIC_ADMIN.LOGIN.PATHS.SEND_OTP,
      exact: true,
      element: renderIfNotLoggedIn(<SendOtp />, UnauthLayout),
    },
    {
      path: ROUTES.CLINIC_ADMIN.LOGIN.PATHS.VERIFY_OTP,
      exact: true,
      element: renderIfNotLoggedIn(<VerifyOtp />, UnauthLayout),
    },
    {
      path: ROUTES.CLINIC_ADMIN.LOGIN.PATHS.EMAIL,
      exact: true,
      element: renderIfNotLoggedIn(<Login />, UnauthLayout),
    },
    {
      path: ROUTES.CLINIC_ADMIN.FORGOT_PASSWORD.PATH,
      exact: true,
      element: renderIfNotLoggedIn(<ForgotPassword />, UnauthLayout),
    },
    {
      path: ROUTES.CLINIC_ADMIN.RESET_PASSWORD.PATH,
      exact: true,
      element: renderIfNotLoggedIn(<ResetPassword />, UnauthLayout),
    },
    {
      path: ROUTES.CLINIC_ADMIN.REGISTER.PATH,
      exact: true,
      element: renderIfNotLoggedIn(<ClinicRegistration />, SignUpLayout),
    },
    {
      path: ROUTES.CLINIC_ADMIN.SUPPORT.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<ClinicAdminSupport />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.DASHBOARD.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<ClinicAdminDashboard />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.PROFILE.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<ClinicAdminProfile />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.PROVIDERS.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<Providers />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.WEBINAR.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<Webinar />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.PATIENTS.ACTUAL_PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<ClinicAdminPatients />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.COMPLETED_PROGRAMS.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<CliniAdminCompletedPatients />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.SLEEP_LOCATIONS.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<SleepLocations />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.SLEEP_BOOKINGS.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<SleepBookings />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.SLEEP_MANUAL_BOOK.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<ManualBook />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.SLEEP_DEVICES.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<SleepDevices />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.ACCOUNT_SETTINGS.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<AccountSettings />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.ACCOUNT_SUBSCRIPTIONS.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<AccountSubscriptions />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.ACCOUNT_TRANSACTIONS.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<AccountTransactions />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.FOTONA_BOOKINGS.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<ClinicAdminFotona />),
    },
    {
      path: ROUTES.CLINIC_ADMIN.FOTONA_LOCATIONS.PATH,
      exact: true,
      element: renderIfClinicAdminLoggedIn(<FotonaLocations />),
    },
  ];

  return (
    <Routes>
      {[
        ...patientRoutes,
        ...providerRoutes,
        ...publicRoutes,
        ...clinicAdminRoutes,
      ].map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          element={route.element}
        />
      ))}
    </Routes>
  );
};

export default Routing;
