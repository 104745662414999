import { Button, Card, Col, Empty, Flex, Modal, Row } from "antd";
import { useState } from "react";
import { postApis } from "../../Common/Apis";
import { NOTIFICATIONS } from "../../Common/Constants";
import { providerViewPatientAudiologySubscriptionsUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import useNotify from "../../Common/Notify";
import { titleizeWord } from "../../Common/Utils";

const Subscriptions = ({ profileDetails, subscriptionDetails }) => {
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const { notify } = useNotify();
  const { confirm } = Modal;

  const showCancelConfirm = (record) => {
    confirm({
      title: "Are you sure?",
      content: "This action will cancel this product subscription.",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        cancelSubscription(record);
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const cancelSubscription = async (record) => {
    setSelectedRow(record);
    setLoading(true);
    await postApis(providerViewPatientAudiologySubscriptionsUrl, {
      action: "cancelled",
      token: profileDetails?.token,
      ...record,
    })
      .then((response) => {
        setLoading(false);
        setSelectedRow();
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        setLoading(false);
        setSelectedRow();
      });
  };

  return subscriptionDetails ? (
    <div>
      <Row gutter={[16, 16]} className="mt1">
        {subscriptionDetails?.map((item, index) => {
          return (
            <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card
                title={item?.product}
                className="box-shadow"
                extra={[
                  item?.status === "active" &&
                    item?.type !== "activeOneTimePayments" && (
                      <Button
                        size="small"
                        key="generate"
                        onClick={() => showCancelConfirm(item)}
                        disabled={
                          selectedRow?.paymentIntentId ===
                            item?.paymentIntentId && loading
                        }
                      >
                        <Loader
                          loading={
                            selectedRow?.paymentIntentId ===
                              item?.paymentIntentId && loading
                          }
                          text={"Cancel"}
                        />
                      </Button>
                    ),
                ]}
              >
                {item?.amount && (
                  <Flex className="mt05">
                    <span className="mr05 grey">Amount:</span>${item?.amount}
                  </Flex>
                )}
                {item?.paymentStatus && (
                  <Flex className="mt05">
                    <span className="mr05 grey">Payment:</span>
                    {titleizeWord(item?.paymentStatus)}
                  </Flex>
                )}
                {item?.status && (
                  <Flex className="mt05">
                    <span className="mr05 grey">Status:</span>
                    {titleizeWord(item?.status)}
                  </Flex>
                )}
                {item?.type && (
                  <Flex className="mt05">
                    <span className="mr05 grey">Type:</span>
                    {titleizeWord(item?.type)}
                  </Flex>
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  ) : (
    <Empty
      description="Payment will be accessible once the insights have been generated."
      imageStyle={{
        height: 70,
      }}
    />
  );
};

export default Subscriptions;
