import { Col, Row } from "antd";
import { useSelector } from "react-redux";

const BlueBanner = ({ children, image, span }) => {
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;

  return (
    <div className="blue-card">
      <Row justify={"space-between"}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24 - span}
          xl={24 - span}
          className="pd2"
        >
          {children}
        </Col>
        {!isMobile && !isTablet && (
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={span || 6}
            xl={span || 6}
            style={{ lineHeight: 0 }}
            className="flex-center"
          >
            <img src={image} alt={image} width="100%" />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default BlueBanner;
