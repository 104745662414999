import { Alert, Button, Card, Form, Slider } from "antd";
import { postApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS } from "../../../Common/Constants";
import { providerViewPatientAudiologyQuestionnairesUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";

const Questionnaires = ({
  contents,
  audiologyQuestionnaires,
  loading,
  setLoading,
  notify,
  profileDetails,
}) => {
  const onFinish = async (values) => {
    const params = {
      ...values,
      type: audiologyQuestionnaires?.type,
      subType: audiologyQuestionnaires?.subType,
      token: profileDetails?.token,
    };
    setLoading(true);
    await postApis(providerViewPatientAudiologyQuestionnairesUrl, params)
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return audiologyQuestionnaires?.hasFilled ? (
    <Alert
      className="mt1"
      message={`The hearing ability is rated ${audiologyQuestionnaires?.hearingAbilityRate} out of 10.`}
      type="success"
      showIcon
    />
  ) : (
    <Card className="mt1">
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
        {contents?.audiology?.questionnaires?.setOne?.questions?.map(
          (item, index) => {
            return (
              <div key={index}>
                <div className="bold mb1">{item?.title}</div>
                <Form.Item
                  size="large"
                  name={item?.value}
                  rules={[
                    {
                      required: true,
                      message: "Please select a value",
                    },
                  ]}
                >
                  <Slider
                    min={1}
                    max={10}
                    step={1}
                    dots
                    marks={{
                      1: "1",
                      2: "2",
                      3: "3",
                      4: "4",
                      5: "5",
                      6: "6",
                      7: "7",
                      8: "8",
                      9: "9",
                      10: "10",
                    }}
                  />
                </Form.Item>

                <div className="text-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    className="mt2"
                  >
                    <Loader loading={loading} text={BUTTONS.SUBMIT} />
                  </Button>
                </div>
              </div>
            );
          }
        )}
      </Form>
    </Card>
  );
};

export default Questionnaires;
