import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Card, Col, Empty, Flex, Row, Splitter } from "antd";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Common/Constants";
import { patientDashboardImages } from "../../Common/Images";

const Sleep = ({ details }) => {
  return (
    <Card
      id="Sleep"
      title={
        <Row gutter={[16, 16]} justify={"space-between"} className="mt05">
          <Col xs={4} sm={3} md={3} lg={3} xl={3}>
            <img src={patientDashboardImages.SLEEP} alt="sleep" width="100%" />
          </Col>
          <Col
            xs={17}
            sm={19}
            md={19}
            lg={19}
            xl={19}
            className="bold fs18 flex-start-center"
          >
            Sleep
          </Col>
          <Col xs={3} sm={2} md={2} lg={2} xl={2} className="flex-center">
            <Link to={ROUTES.PATIENT.HEALTH.PATH}>
              <img
                src={patientDashboardImages.ORANGE_VIEWMORE}
                alt="sleep"
                width="100%"
              />
            </Link>
          </Col>
        </Row>
      }
      className="bdr8 box-more-shadow"
      styles={{ body: { padding: "0" }, color: "#141414" }}
    >
      {details ? (
        <Splitter>
          <Splitter.Panel collapsible={false} resizable={false}>
            <div className="pd-tlr1-b0">
              <div className="grey fs12">Total Sleep Duration</div>
              <Flex wrap="wrap" gap="small" className="flex-baseline">
                <>
                  <div className="fs24 bold">{details?.total?.hrs}</div>
                  <div className="grey fs12">hrs</div>
                </>
                <>
                  <div className="fs24 bold">{details?.total?.mins}</div>
                  <div className="grey fs12">mins</div>
                </>
              </Flex>
              <Flex wrap="wrap" gap="small" className="fs12 mt05">
                <div className="grey">Source:</div>
                <div>{details?.source}</div>
              </Flex>
            </div>

            <div className="text-center mt1">
              <img
                src={patientDashboardImages.SLEEP_BANNER}
                alt="sleep"
                width="70%"
              />
            </div>

            <Row
              gutter={[16, 8]}
              justify={"end"}
              className="pd05"
              style={{ bottom: 0, position: "absolute", width: "52%" }}
            >
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="grey fs12 text-right"
              >
                <CalendarOutlined className="mr05" />
                {details?.date}
              </Col>
              <Col
                xs={24}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                className="grey fs12 text-right"
              >
                <ClockCircleOutlined className="mr05" />
                {details?.time}
              </Col>
            </Row>
          </Splitter.Panel>

          <Splitter.Panel>
            <Splitter layout="vertical">
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pd1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col span={6}>
                      <img
                        src={patientDashboardImages.DEEP_SLEEP}
                        alt="deep"
                        width="100%"
                      />
                    </Col>
                    <Col span={18}>
                      <div className="grey fs12">Deep Sleep</div>
                      <Flex wrap="wrap" gap="small" className="flex-baseline">
                        <>
                          <div className="fs24 bold">{details?.deep?.hrs}</div>
                          <div className="grey fs12">hrs</div>
                        </>
                        <>
                          <div className="fs24 bold">{details?.deep?.mins}</div>
                          <div className="grey fs12">mins</div>
                        </>
                      </Flex>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pd1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col span={6}>
                      <img
                        src={patientDashboardImages.LIGHT_SLEEP}
                        alt="light"
                        width="100%"
                      />
                    </Col>
                    <Col span={18}>
                      <div className="grey fs12">Light Sleep</div>
                      <Flex wrap="wrap" gap="small" className="flex-baseline">
                        <>
                          <div className="fs24 bold">{details?.light?.hrs}</div>
                          <div className="grey fs12">hrs</div>
                        </>
                        <>
                          <div className="fs24 bold">
                            {details?.light?.mins}
                          </div>
                          <div className="grey fs12">mins</div>
                        </>
                      </Flex>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pd1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col span={6}>
                      <img
                        src={patientDashboardImages.REM_SLEEP}
                        alt="rem"
                        width="100%"
                      />
                    </Col>
                    <Col span={18}>
                      <div className="grey fs12">REM Sleep</div>
                      <Flex wrap="wrap" gap="small" className="flex-baseline">
                        <>
                          <div className="fs24 bold">{details?.rem?.hrs}</div>
                          <div className="grey fs12">hrs</div>
                        </>
                        <>
                          <div className="fs24 bold">{details?.rem?.mins}</div>
                          <div className="grey fs12">mins</div>
                        </>
                      </Flex>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
            </Splitter>
          </Splitter.Panel>
        </Splitter>
      ) : (
        <Empty
          imageStyle={{
            height: 60,
          }}
          className="pd1 fs13"
          description={"No data available"}
        />
      )}
    </Card>
  );
};

export default Sleep;
