import { Avatar, Col, Divider, Flex, Row, Typography } from "antd";
import { displayUserName } from "../../Common/Utils";
import COVER_PHOTO from "../../../Assets/images/coverPhoto.png";
import { FORM_FIELDS } from "../../Common/Constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import AddLogo from "./AddLogo";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis } from "../../Common/Apis";
import { clinicAdminProfileMetaUrl } from "../../Common/Endpoints";
import SupportEmail from "./SupportEmail";
const { Title } = Typography;

const ClinicAdminProfile = () => {
  const { dropdowns } = useSelector((state) => state?.remoteConfig);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { showLoader, setShowLoader } = useApiLoader();
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const clinics = dropdowns?.common?.clinics;
  const [metaDetails, setMetaDetails] = useState();
  const profileDetails = useSelector((state) => state.profile?.profileDetails);
  const dispatch = useDispatch();

  const getProfileMetaDetails = async () => {
    setShowLoader(true);
    await getApis(clinicAdminProfileMetaUrl)
      .then((response) => {
        setShowLoader(false);
        setMetaDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    if (Object.entries(profileDetails).length === 0) {
      dispatch(setProfileDetails(userDetails));
    }

    getProfileMetaDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProfileDetails = (label, value) => {
    if (value) {
      return (
        <Row gutter={[16, 16]} className="mt1">
          <Col span={24} className="flex-center">
            <Flex wrap="wrap" gap="small" vertical={isMobile ? true : false}>
              <div>{label}: </div>
              <div className="grey">
                {Array.isArray(value) ? value.join(", ") : value}
              </div>
            </Flex>
          </Col>
        </Row>
      );
    }
  };

  return (
    !showLoader && (
      <Row
        gutter={[16, 16]}
        justify="space-between"
        data-testid="provider-profile"
      >
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div className="small-left-widget">
            <div className="cover-photo-container">
              <img
                src={COVER_PHOTO}
                alt="CoverPic"
                className="fit cover-photo"
              />
              <Avatar size={94} className="avatar avatar-letter">
                {displayUserName()?.charAt(0).toUpperCase()}
              </Avatar>
            </div>
            <div className="text-center mt4">
              <Row>
                <Col span={24}>
                  <Title level={3}>{displayUserName()}</Title>
                </Col>
              </Row>

              <Divider style={{ margin: "0.5rem 0 1.5rem 0" }} />
              <div className="mb2">
                {renderProfileDetails(
                  FORM_FIELDS.CATEGORY,
                  userDetails?.specialization
                )}
                {renderProfileDetails(
                  FORM_FIELDS.CLINIC,
                  clinics?.find((item) => item?.value === userDetails?.clinic)
                    ?.label
                )}
                {renderProfileDetails(FORM_FIELDS.ROLE, userDetails?.role)}
                {renderProfileDetails(FORM_FIELDS.GENDER, userDetails?.gender)}
                {renderProfileDetails(FORM_FIELDS.EMAIL, userDetails?.email)}
                {renderProfileDetails(
                  FORM_FIELDS.PHONE,
                  userDetails?.phoneNumber
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <AddLogo metaDetails={metaDetails} />
          <SupportEmail metaDetails={metaDetails} />
        </Col>
      </Row>
    )
  );
};

export default ClinicAdminProfile;
