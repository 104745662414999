import { Col, Collapse, Row } from "antd";
import { useState } from "react";

const General = ({ searchTerm, content }) => {
  const [selectedKey, setSelectedKey] = useState("1");

  const filteredItems = content?.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOnClick = (e) => {
    setSelectedKey(e.key);
  };

  return (
    <Row className="mt1" data-testid="general">
      <Col span={24}>
        {filteredItems?.map((item, index) => {
          return (
            <Collapse
              key={index}
              size="large"
              expandIconPosition="end"
              items={[
                {
                  key: item?.key,
                  label: item?.label,
                  children: (
                    <div dangerouslySetInnerHTML={{ __html: item?.children }} />
                  ),
                },
              ]}
              defaultActiveKey={[selectedKey]}
              onClick={handleOnClick}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default General;
