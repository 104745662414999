import { Button, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmrSelectedKey } from "../../../../Reducers/patientSlice";
import { postApis } from "../../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  PLACEHOLDER,
  VALIDATIONS,
} from "../../../Common/Constants";
import { providerViewPatientEmrDataUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import { renderKeyValueDetails } from "../../../Common/Utils";

const VitalDetails = ({ emrDetails, token, getEmrData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(providerViewPatientEmrDataUrl, {
      token: token,
      category: "vitalDetails",
      formData: values,
    })
      .then(() => {
        setLoading(false);
        getEmrData();
        dispatch(setEmrSelectedKey(4));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return Object.keys(emrDetails?.vitalDetails)?.length > 0 ? (
    <Row>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.BLOOD_PRESSURE,
          emrDetails?.vitalDetails?.bloodPressure
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.HEART_RATE,
          emrDetails?.vitalDetails?.heartRate
        )}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.SPO2,
          emrDetails?.vitalDetails?.oxygenSaturation
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.RESPIRATORY_RATE,
          emrDetails?.vitalDetails?.respiratoryRate
        )}
      </Col>
    </Row>
  ) : (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={{
        required: VALIDATIONS.DEFAULT.BLANK,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.RESPIRATORY_RATE}
            name="respiratoryRate"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(\d+(\.\d+)?,)*\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.RESPIRATORY_RATE} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.HEART_RATE}
            name="heartRate"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(\d+(\.\d+)?,)*\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.HEART_RATE} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.BLOOD_PRESSURE}
            name="bloodPressure"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(\d+\/\d+)(,\d+\/\d+)*$/,
                message: VALIDATIONS.PATTERN.BP,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.BLOOD_PRESSURE} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.SPO2}
            name="oxygenSaturation"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^(\d+(\.\d+)?,)*\d+(\.\d+)?$/,
                message: VALIDATIONS.PATTERN.NUMERIC,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.SPO2} />
          </Form.Item>
        </Col>
      </Row>

      <Row className="flex-center">
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item className="mt1">
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default VitalDetails;
