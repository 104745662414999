import { useEffect, useState, createContext } from "react";
import { useDispatch } from "react-redux";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "./firebase";
import { setRemoteConfig } from "./Reducers/remoteConfigSlice";

export const RemoteConfigContext = createContext(null);

export const RemoteConfigProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchRemoteConfig = async () => {
      try {
        remoteConfig.settings.minimumFetchIntervalMillis = 60000;
        await fetchAndActivate(remoteConfig);

        const metaKeysString = getValue(remoteConfig, "META_KEYS")?.asString();
        let metaKeys;
        try {
          metaKeys = JSON.parse(metaKeysString);
        } catch (error) {
          metaKeys = {};
        }

        const envConfig = metaKeys?.[process.env.REACT_APP_ENV] || {};

        const fetchedData = Object.entries(envConfig).reduce(
          (acc, [key, remoteKey]) => {
            const rawValue = getValue(remoteConfig, remoteKey).asString();
            try {
              acc[key] = JSON.parse(rawValue);
            } catch {
              acc[key] = rawValue;
            }
            return acc;
          },
          {}
        );

        dispatch(setRemoteConfig(fetchedData));
      } catch (error) {
        console.error("Error fetching remote config:", error);
      } finally {
        setIsFetching(false); 
      }
    };

    fetchRemoteConfig();
  }, [dispatch]);

  return (
    <RemoteConfigContext.Provider value={{ isFetching }}>
      {children}
    </RemoteConfigContext.Provider>
  );
};
