import React, { useEffect } from "react";
import VideoPlayer from "../../Common/VideoPlayer";
import { logAnalyticsEvent } from "../../Common/Utils";
import { Card, Divider } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const findObjectById = (data, id) => {
  let foundObject = null;
  data?.some((item) => {
    foundObject = item?.list.find((listItem) => listItem?.id === id);
    return foundObject;
  });
  return foundObject;
};

const ShowVideo = () => {
  const { videos } = useSelector((state) => state?.remoteConfig);
  const { id } = useParams();
  const data = findObjectById(videos?.videos, id);

  useEffect(() => {
    logAnalyticsEvent("VideoViews", {
      id: id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pd2" data-testid="show-video">
      <Card className="box-shadow">
        <div className="fs24 bold orange text-center">{data?.title}</div>
        <Divider style={{ margin: "0.5rem 0 2rem 0" }} />
        <VideoPlayer
          autoPlay={true}
          link={data?.link}
          thumbnailWithIcon={data?.thumbnails?.withIcon}
          thumbnailWithoutIcon={data?.thumbnails?.withoutIcon}
        />
      </Card>
    </div>
  );
};

export default ShowVideo;
