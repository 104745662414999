import { Card, Col, Divider, Empty, Result, Row } from "antd";
import { useEffect, useState } from "react";
import { getApis, postApis } from "../../Common/Apis";
import { patientTimelineUrl } from "../../Common/Endpoints";
import { NOTIFICATIONS } from "../../Common/Constants";
import useNotify from "../../Common/Notify";
import DynamicList from "./DynamicList";
import { useNavigate } from "react-router-dom";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { useSelector } from "react-redux";

const Timelines = () => {
  const { timelines } = useSelector((state) => state?.remoteConfig);
  const [apiData, setApiData] = useState();
  const [loading, setLoading] = useState(false);
  const { showLoader, setShowLoader } = useApiLoader();
  const [surgeryCompleted, setSurgeryCompleted] = useState(false);
  const { notify } = useNotify();
  const navigate = useNavigate();

  const getTimelineApiData = async () => {
    setShowLoader(true);
    await getApis(patientTimelineUrl, {})
      .then((response) => {
        setShowLoader(false);
        setApiData(response?.data);
        setSurgeryCompleted(response?.data?.surgeryStatus);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getTimelineApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (body) => {
    await postApis(patientTimelineUrl, body)
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onBtnClick = (clinic, service, timelineStaticData, key, item, type) => {
    if (timelineStaticData[key]?.cards[item]?.buttonAction === "redirect") {
      navigate(timelineStaticData[key]?.cards[item]?.redirectPath);
    } else {
      setLoading(true);
      onSubmit({
        [type]: {
          [key]: {
            cards: { [item]: { completed: true } },
          },
        },
        clinic,
        service,
      });
    }
  };

  return (
    !showLoader && (
      <div data-testid="patient-timelines">
        {surgeryCompleted ? (
          <Card>
            <Result status="success" title="Your surgery has been completed" />
          </Card>
        ) : (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {apiData ? (
                apiData?.map((item, index) => {
                  return Object.entries(item?.data?.[item?.key]).length > 0 ? (
                    <div key={index}>
                      <div className={"orange bold fs20"}>
                        {timelines?.[item?.clinic]?.label}
                      </div>
                      <Divider style={{ margin: "0.25rem 0 1.75rem 0" }} />

                      <DynamicList
                        clinic={item?.clinic}
                        service={item?.service}
                        timelineStaticData={
                          timelines?.[item?.clinic]?.[item?.key]
                        }
                        timelineApiData={item?.data?.[item?.key]}
                        onBtnClick={onBtnClick}
                        loading={loading}
                        type={item?.key}
                      />
                    </div>
                  ) : (
                    <Card key={index}>
                      <Result title="No data found" />
                    </Card>
                  );
                })
              ) : (
                <Card>
                  <Empty />
                </Card>
              )}
            </Col>
          </Row>
        )}
      </div>
    )
  );
};

export default Timelines;
