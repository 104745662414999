import { Card, Divider, Empty, Result } from "antd";
import { useEffect, useState } from "react";
import { getApis, postApis } from "../../Common/Apis";
import { NOTIFICATIONS, SERVICES } from "../../Common/Constants";
import { patientQuestionnairesUrl } from "../../Common/Endpoints";
import { displayDateTime } from "../../Common/Utils";
import useNotify from "../../Common/Notify";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import TinnitusForm from "./TinnitusForm";
import TinnitusRating from "./TinnitusRating";
import { useSelector } from "react-redux";

const Questionnaires = () => {
  const { questionnaires } = useSelector((state) => state?.remoteConfig);
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const { notify } = useNotify();

  const getQuestionnaireApiData = async () => {
    setShowLoader(true);
    await getApis(patientQuestionnairesUrl)
      .then((response) => {
        getClinicBasedGroupedData(response?.data);
        setShowLoader(false);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getQuestionnaireApiData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const {
      clinic,
      service,
      type,
      subType,
      feet,
      inches,
      lbsWeight,
      ...answers
    } = values;

    const data = {
      ...{ clinic, service, type, subType },
      answers,
    };
    await postApis(patientQuestionnairesUrl, data)
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = async () => {
    setLoading(false);
  };

  const getClinicBasedGroupedData = (data) => {
    setApiData(
      data?.reduce((acc, item) => {
        if (!acc[item.clinic]) {
          acc[item.clinic] = [];
        }
        acc[item.clinic].push(item);
        return acc;
      }, {})
    );
  };

  return (
    !showLoader && (
      <div data-testid="patient-questionnaires">
        {apiData && Object.entries(apiData).length > 0 ? (
          Object.keys(apiData)?.map((clinic, fIndex) => (
            <div key={fIndex} className={fIndex > 0 ? "mt2" : ""}>
              <div className={"orange bold fs20"}>
                {questionnaires?.[clinic]?.label}
              </div>
              <Divider style={{ margin: "0.25rem 0 1.75rem 0" }} />
              {apiData?.[clinic]?.map((mainItem, mainIndex) => {
                return mainItem?.submitted
                  ? mainItem?.service !== SERVICES.SLEEP &&
                      mainItem?.service === SERVICES.TINNITUS &&
                      mainItem?.subType !== "setTwo" && (
                        <Card key={mainIndex} className="mt1">
                          <Result
                            title={`${mainItem?.service} - ${
                              questionnaires?.[clinic]?.[mainItem?.service]?.[
                                mainItem?.type
                              ]?.[mainItem?.subType]?.label
                            } has already been submitted`}
                            subTitle={
                              <div>
                                <span className="bold mr05">
                                  <ClockCircleOutlined /> Last Submitted On:
                                </span>
                                <span>
                                  {displayDateTime(mainItem?.lastUpdated)}
                                </span>
                              </div>
                            }
                          />
                        </Card>
                      )
                  : mainItem?.service === SERVICES.TINNITUS && (
                      <div key={mainIndex}>
                        {mainItem?.type === "ratings" ? (
                          <TinnitusRating
                            mainItem={mainItem}
                            loading={loading}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                          />
                        ) : (
                          <TinnitusForm
                            mainItem={mainItem}
                            loading={loading}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            questionnaireDetails={
                              questionnaires?.[clinic]?.[SERVICES.TINNITUS]
                            }
                          />
                        )}
                      </div>
                    );
              })}
            </div>
          ))
        ) : (
          <Card>
            <Empty />
          </Card>
        )}
      </div>
    )
  );
};

export default Questionnaires;
