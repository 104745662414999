import { Button, Col, Form, Input, Radio, Row, Select, Typography } from "antd";
import { MaskedInput } from "antd-mask-input";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingDetails,
  setPatientDetails,
  setReOrderDetails,
  setTimeSlots,
} from "../../../../Reducers/bookingsSlice";
import { getApis } from "../../../Common/Apis";
import { BUTTONS, FORM_FIELDS, VALIDATIONS } from "../../../Common/Constants";
import {
  clinicAdminSleepSlotsUrl,
  bookingsLocationsUrl,
} from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import {
  extractCode,
  getOnlyWeekDays,
  processedPhoneNumber,
  validateDate,
  validatePhoneLength,
} from "../../../Common/Utils";
const { Option } = Select;
const { Title } = Typography;

const UserDetails = ({ setCurrentStep, reOrderDetails }) => {
  const { dropdowns } = useSelector((state) => state?.remoteConfig);
  const [form] = Form.useForm();
  const bookings = useSelector((state) => state?.bookings);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState();
  const dispatch = useDispatch();
  const currentDate = moment();
  const patientDetails = bookings?.patientDetails;

  const getLocations = async () => {
    await getApis(bookingsLocationsUrl, { type: "sleep" })
      .then((response) => {
        setLocations(response?.data);
      })
      .catch(() => {});
  };

  const getFormData = () => {
    if (reOrderDetails) {
      return {
        code: reOrderDetails?.code || "+1",
        days: reOrderDetails?.days || 2,
        lbsWeight: reOrderDetails?.weight || "",
        feet: reOrderDetails?.height?.split(".")[0] || "",
        inches: reOrderDetails?.height?.split(".")[1] || "",
        ...reOrderDetails,
        phoneNumber: extractCode(reOrderDetails?.phoneNumber),
        dateOfBirth:
          reOrderDetails?.dateOfBirth &&
          moment(reOrderDetails?.dateOfBirth, [
            "MM-DD-YYYY",
            "YYYY-MM-DD",
          ]).format("MM/DD/YYYY"),
      };
    } else {
      return {
        code: patientDetails?.code || "+1",
        days: patientDetails?.days || 2,
        ...patientDetails,
        phoneNumber: extractCode(patientDetails?.phoneNumber),
        dateOfBirth:
          patientDetails?.dateOfBirth &&
          moment(patientDetails?.dateOfBirth, [
            "MM-DD-YYYY",
            "YYYY-MM-DD",
          ]).format("MM/DD/YYYY"),
      };
    }
  };

  useEffect(() => {
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prefixSelector = (
    <Form.Item name="code" noStyle>
      <Select style={{ width: 70 }} name="code">
        <Option value="+1">+1</Option>
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );

  const onFinish = async (values) => {
    setLoading(true);
    const phone = await processedPhoneNumber(values);

    const returnDate = getOnlyWeekDays(
      currentDate.format("YYYY-MM-DD"),
      values?.days
    );
    const patientParams = {
      dateOfBirth: moment(values?.dateOfBirth).format("MM-DD-YYYY"),
      phoneNumber: phone,
    };
    const bookingsParams = {
      bookingDate: currentDate.format("YYYY-MM-DD"),
      returnDate: returnDate.format("YYYY-MM-DD"),
      location: values?.location,
      days: values?.days,
    };
    dispatch(setPatientDetails({ ...values, ...patientParams }));
    dispatch(setReOrderDetails({ ...values, ...patientParams }));
    await getApis(clinicAdminSleepSlotsUrl, {
      ...bookingsParams,
      ...patientParams,
    })
      .then((response) => {
        dispatch(setTimeSlots(response?.data));
        dispatch(setBookingDetails(bookingsParams));
        setLoading(false);
        setCurrentStep(1);
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <div className="mt2">
      <Title level={2} className="text-center">
        Patient Details
      </Title>
      <Form
        initialValues={getFormData()}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
          types: {
            email: VALIDATIONS.DEFAULT.INVALID_EMAIL,
          },
        }}
        className="mt2"
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.FIRST_NAME}
              name="firstName"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: VALIDATIONS.PATTERN.STRING,
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.FIRST_NAME} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.LAST_NAME}
              name="lastName"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: VALIDATIONS.PATTERN.STRING,
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.LAST_NAME} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.PHONE}
              name="phoneNumber"
              size="large"
              rules={[
                {
                  required: true,
                  validator: validatePhoneLength,
                },
              ]}
            >
              <MaskedInput
                addonBefore={prefixSelector}
                mask={"(000)000-0000"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.EMAIL}
              name="email"
              size="large"
              rules={[
                {
                  type: "email",
                },
              ]}
            >
              <Input placeholder={FORM_FIELDS.EMAIL_ADDRESS} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.GENDER}
              name="gender"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                name="gender"
                placeholder="Please Select"
                options={dropdowns?.common?.genders}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.DOB}
              name="dateOfBirth"
              size="large"
              rules={[
                {
                  required: true,
                },
                {
                  validator: validateDate,
                },
              ]}
            >
              <MaskedInput mask={"00/00/0000"} placeholder="MM/DD/YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Form.Item label="Height in Feet" name="feet">
              <Input
                type="number"
                placeholder="Feet"
                min={1}
                step={1}
                max={12}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Form.Item label="Height in Inches" name="inches">
              <Input
                type="number"
                placeholder="Inches"
                min={0}
                step={1}
                max={11}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.LBS_WEIGHT}
              name="lbsWeight"
              size="large"
            >
              <Input type="number" placeholder="150" min={1} step={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={FORM_FIELDS.LOCATION}
              name="location"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                name="location"
                placeholder="Please Select"
                options={locations}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Rent device for?"
              name="days"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group className="mt05">
                <Radio value={1}>1 Day</Radio>
                <Radio value={2}>2 Days</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className="flex-center mt1">
          <Col xs={24} sm={24} md={8} lg={8} xl={4}>
            <Form.Item>
              <Button type="primary" htmlType="submit" block disabled={loading}>
                <Loader loading={loading} text={BUTTONS.NEXT} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UserDetails;
