import { Card, Tag } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { displayOnlyDate } from "../../Common/Utils";

const Timelines = ({ timelineDetails, clinic }) => {
  const { timelines } = useSelector((state) => state?.remoteConfig);
  const sortedData = [...Object.keys(timelines?.[clinic]?.preProcedure)].sort(
    (a, b) => {
      const weekNumberA = parseInt(a.split(" ").pop());
      const weekNumberB = parseInt(b.split(" ").pop());
      return weekNumberB - weekNumberA;
    }
  );
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;
  const preSurgeryData = timelineDetails?.preProcedure;

  const gridStyle = {
    width: isMobile || isTablet ? "50%" : "20%",
    textAlign: "center",
    background: "#f8f8f8",
  };

  return (
    <Card
      title={timelineDetails?.surgeryStatus ? "Post Surgery" : "Pre Surgery"}
      extra={
        <div>
          <span className="bold mr05">
            <ClockCircleOutlined /> Surgery Date:
          </span>
          <span>{displayOnlyDate(timelineDetails?.surgeryDate)}</span>
        </div>
      }
    >
      {sortedData?.map((key, index) => {
        return (
          <Card.Grid key={index} style={gridStyle}>
            <div className="fs18 bold blue">{key}</div>
            <div className="grey fs12">
              {preSurgeryData[key]?.dates?.startDate}
              {preSurgeryData[key]?.dates?.endDate &&
                ` - ${preSurgeryData[key]?.dates?.endDate}`}
            </div>

            <div className="mt05 bold">
              {preSurgeryData[key]?.completed ? (
                <div className="green">Completed</div>
              ) : (
                <div className="orange">Not Completed</div>
              )}
            </div>

            <div className="mt1 bold">
              {Object.keys(timelines?.[clinic]?.preProcedure[key]?.cards)?.map(
                (item, index) => {
                  return (
                    <Tag key={index}>
                      {
                        timelines?.[clinic]?.preProcedure[key]?.cards[item]
                          ?.title
                      }
                    </Tag>
                  );
                }
              )}
            </div>
          </Card.Grid>
        );
      })}
    </Card>
  );
};

export default Timelines;
