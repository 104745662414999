import { Button, Checkbox, Col, Form, Input, Row, Segmented } from "antd";
import { useState } from "react";
import { BUTTONS, VALIDATIONS } from "../../../Common/Constants";
import Loader from "../../../Common/Loader";
import Popup from "../../../Common/Popup";
const { TextArea } = Input;

const Product = ({
  form,
  formTitle,
  loading,
  isModalOpen,
  handleCancel,
  onFinish,
  onFinishFailed,
  selectedRow,
}) => {
  const [paymentType, setPaymentType] = useState(
    selectedRow?.paymentType || "Subscription"
  );
  return (
    <Popup
      title={formTitle}
      open={isModalOpen}
      cancel={handleCancel}
      footer={null}
      width={600}
      style={{ top: "25px" }}
    >
      <Form
        initialValues={{ paymentType: paymentType || "Subscription" }}
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={{
          required: VALIDATIONS.DEFAULT.BLANK,
        }}
      >
        <Form.Item
          label="Product"
          name="product"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Product Name" />
        </Form.Item>

        <Form.Item
          label="Payment Type"
          name="paymentType"
          rules={[
            {
              required: true,
            },
          ]}
          hidden={!!selectedRow}
        >
          <Segmented
            options={["Subscription", "One Time", "Hybrid"]}
            onChange={(value) => {
              setPaymentType(value);
            }}
            size={"large"}
          />
        </Form.Item>

        {["One Time", "Hybrid"].includes(paymentType) && (
          <Form.Item
            label="One Time Amount"
            name="oneTimeAmount"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^[0-9]+$/,
                message: "Only numbers are allowed",
              },
            ]}
          >
            <Input placeholder="Amount" />
          </Form.Item>
        )}

        {["Subscription", "Hybrid"].includes(paymentType) && (
          <>
            <Form.Item
              label="Subscription Amount"
              name="subscriptionAmount"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Only numbers are allowed",
                },
              ]}
            >
              <Input placeholder="Amount" />
            </Form.Item>
            <Form.Item name="lifetimeSubscription" valuePropName="checked">
              <Checkbox>Life Time Subscription?</Checkbox>
            </Form.Item>

            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                const isLifetime = getFieldValue("lifetimeSubscription");

                return !isLifetime ? (
                  <Form.Item
                    label="Subscription Period"
                    name="durationInMonth"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Only numbers are allowed",
                      },
                    ]}
                  >
                    <Input placeholder="Duration In Months" />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TextArea rows={3} placeholder="Product Description" />
        </Form.Item>
        <Row className="flex-center mt1">
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Popup>
  );
};

export default Product;
