import {
  patientBookingsListUrl,
  patientBookingsUrl,
} from "../../Common/Endpoints";
import { ROUTES } from "../../Common/Constants";
import Bookings from "../../Common/Bookings";
import { useEffect } from "react";

const PatientBookings = () => {
  useEffect(() => {
    localStorage.removeItem("bookingDetails");
  }, []);

  return (
    <>
      <Bookings
        getUrl={patientBookingsListUrl}
        redirectUrl={ROUTES.PATIENT.SLEEP_STUDY.PATH}
        putUrl={patientBookingsUrl}
        locationType="sleep"
      />
    </>
  );
};

export default PatientBookings;
