import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  DollarOutlined,
  SignatureOutlined,
  InfoCircleOutlined,
  InsuranceOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { getPageHeader } from "../../../Common/Utils";

export const displayConfirmInfo = (details) => {
  return [
    {
      icon: CalendarOutlined,
      label: "Scheduled For",
      value: `${moment(details?.bookingDate).format("MM/DD/YYYY")} (${
        details?.slot
      })`,
    },
    {
      icon: ClockCircleOutlined,
      label: "Number of Days",
      value: details?.days,
    },
    {
      icon: EnvironmentOutlined,
      label: "Location",
      value: details?.location,
    },
    {
      icon: CalendarOutlined,
      label: "Return Date",
      value: moment(details?.returnDate).format("MM/DD/YYYY"),
    },
    {
      icon: SignatureOutlined,
      label: "Document Signed",
      value: details?.sign ? "Yes" : "No",
    },
    {
      icon: DollarOutlined,
      label: "Payment Method",
      value: details?.paymentStatus,
    },
    {
      icon: UserAddOutlined,
      label: "Ordering Provider",
      value: details?.orderingProvider,
    },
    {
      icon: InfoCircleOutlined,
      label: "Payment Status",
      value: details?.stripePaymentStatus
        ? details?.stripePaymentStatus === "NA"
          ? details?.stripePaymentStatus
          : getPageHeader(details?.stripePaymentStatus)
        : null,
    },
    {
      icon: InsuranceOutlined,
      label: "Insurance",
      value: details?.paymentStatus === "Insurance" ? details?.insurance : null,
    },
  ];
};

export const displayReviewInfo = (details) => {
  return [
    {
      icon: CalendarOutlined,
      label: "Scheduled For",
      value: `${moment(details?.bookingDate).format("MM/DD/YYYY")} (${
        details?.slot
      })`,
    },
    {
      icon: ClockCircleOutlined,
      label: "Number of Days",
      value: details?.days,
    },
    {
      icon: EnvironmentOutlined,
      label: "Location",
      value: details?.location,
    },
    {
      icon: CalendarOutlined,
      label: "Return Date",
      value: moment(details?.returnDate).format("MM/DD/YYYY"),
    },
    {
      icon: UserAddOutlined,
      label: "Ordering Provider",
      value: details?.orderingProvider,
    },
    {
      icon: DollarOutlined,
      label: "Payment Method",
      value: details?.paymentStatus,
    },
    {
      icon: InsuranceOutlined,
      label: "Insurance",
      value: details?.paymentStatus === "Insurance" ? details?.insurance : null,
    },
  ];
};
