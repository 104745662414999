import {
  ClockCircleOutlined,
  CloseOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Form, Modal, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTimeSlots } from "../../../Reducers/bookingsSlice";
import { getApis, putApis } from "../../Common/Apis";
import { NOTIFICATIONS } from "../../Common/Constants";
import { clinicAdminWebinarBookingsUrl } from "../../Common/Endpoints";
import useNotify from "../../Common/Notify";
import { displayOnlyDate } from "../../Common/Utils";
import ChooseSlot from "./ChooseSlot";
import Reschedule from "./Reschedule";
import { useApiLoader } from "../../Common/ApiLoaderContext";

const Webinar = () => {
  const { setShowFullLoader } = useApiLoader();
  const [showLoader, setShowLoader] = useState(false);
  const [webinarDetails, setWebinarDetails] = useState();
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const { confirm } = Modal;
  const { notify } = useNotify();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const getWebinarBookings = async () => {
    setShowLoader(true);
    await getApis(clinicAdminWebinarBookingsUrl)
      .then((response) => {
        setShowLoader(false);
        setWebinarDetails(response?.data);
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getWebinarBookings();
  }, []);

  const showRescheduleModal = (record) => {
    form.resetFields();
    setSelectedRow(record);
    setRescheduleModalOpen(true);
  };

  const cancelRescheduleModal = () => {
    setRescheduleModalOpen(false);
    setSelectedRow(null);
    dispatch(setTimeSlots([]));
  };

  const showCancelConfirm = (item) => {
    confirm({
      title: "Are you sure?",
      content: "This action will cancel your booking.",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        cancelBookings(item);
      },
      onCancel() {},
    });
  };

  const cancelBookings = async (record) => {
    setShowFullLoader(true);
    await putApis(clinicAdminWebinarBookingsUrl, {
      bookingID: record?.bookingID,
      action: "cancelled",
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setShowFullLoader(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const columns = [
    {
      title: "Scheduled Date",
      dataIndex: "bookingDate",
      render: (_, record) =>
        record?.bookingDate ? displayOnlyDate(record?.bookingDate) : "-",
    },
    {
      title: "Booking ID",
      dataIndex: "bookingID",
    },
    {
      title: "Slot",
      dataIndex: "slot",
    },
    {
      title: "Status",
      dataIndex: "bookingStatus",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => {
        return (
          <Dropdown
            className="flex-center"
            menu={{
              items: [
                {
                  key: "1",
                  label: "Reschedule",
                  icon: <ClockCircleOutlined />,
                  onClick: () => showRescheduleModal(record),
                },
                {
                  key: "2",
                  label: "Cancel",
                  icon: <CloseOutlined />,
                  onClick: () => showCancelConfirm(record),
                },
              ].filter((item) =>
                record?.bookingStatus === "CANCELLED"
                  ? !["2"].includes(item.key)
                  : item
              ),
            }}
          >
            <Button block size="small" name="webinarActions">
              <Space>
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    !showLoader && (
      <>
        {rescheduleModalOpen && (
          <Reschedule
            form={form}
            modalOpen={rescheduleModalOpen}
            cancelModal={cancelRescheduleModal}
            selectedRow={selectedRow}
          />
        )}
        <Table
          bordered
          rowKey={(record) => new Date(record?.created).getTime()}
          columns={columns}
          dataSource={webinarDetails}
          scroll={{ x: "100%" }}
          pagination={false}
        />
        <ChooseSlot />;
      </>
    )
  );
};

export default Webinar;
