import { LinkOutlined } from "@ant-design/icons";
import { Empty, Table } from "antd";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { getApis } from "../../../Common/Apis";
import { clinicAdminTransactionsUrl } from "../../../Common/Endpoints";

const AccountTransactions = () => {
  const [transactionDetails, setTransactionDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();

  const getTransactionDetails = async () => {
    setShowLoader(true);
    await getApis(clinicAdminTransactionsUrl)
      .then((response) => {
        setShowLoader(false);
        setTransactionDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getTransactionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Invoice URL",
      dataIndex: "invoiceUrl",
      render: (url) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <LinkOutlined /> Click Here
        </a>
      ),
    },
  ];

  return (
    !showLoader && (
      <Table
        bordered
        rowKey={(record) => record?.invoiceNumber}
        columns={columns}
        dataSource={transactionDetails}
        scroll={{ x: "100%" }}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              description="You can view your transactions and invoices here."
              imageStyle={{
                height: 70,
              }}
            />
          ),
        }}
      />
    )
  );
};

export default AccountTransactions;
