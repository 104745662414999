import { InfoCircleOutlined, ShareAltOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Flex,
  Form,
  Popover,
  Row,
} from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { postApis } from "../../../Common/Apis";
import { FORM_FIELDS, NOTIFICATIONS } from "../../../Common/Constants";
import { providerViewPatientReferralUrl } from "../../../Common/Endpoints";
import { patientDashboardImages } from "../../../Common/Images";
import useNotify from "../../../Common/Notify";
import {
  processedFaxNumber,
  processedPhoneNumber,
} from "../../../Common/Utils";
import BarGraph from "../Audiology/BarGraph";
import Refer from "../Audiology/Refer";

const WellnessInsights = ({
  profileDetails,
  wellnessInsightsDetails,
  content,
}) => {
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();

  const renderProfileDetails = (label, value) => {
    if (value) {
      return (
        <Flex
          className="mb05"
          wrap="wrap"
          gap="small"
          vertical={isMobile ? true : false}
        >
          <div className="bold">{label}: </div>
          <div>{value}</div>
        </Flex>
      );
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
    form.resetFields();
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (
      ["", "(___)___-____"].includes(values?.faxNumber) &&
      (values?.providerEmail === "" || !values?.providerEmail)
    ) {
      notify(NOTIFICATIONS.ERROR.KEY, "Either FAX Number or Email is required");
      setLoading(false);
    } else {
      let fax;
      const phone = await processedPhoneNumber(values);
      fax = await processedFaxNumber(values?.faxCode, values?.faxNumber);
      const params = {
        ...values,
        referralPhoneNumber: phone,
        referralFaxNumber: fax,
        token: profileDetails?.token,
      };
      if (values?.providerEmail) {
        delete params["faxNumber"];
        if (
          ["", "(___)___-____", "__________"].includes(values?.faxNumber) ||
          !values?.faxNumber
        ) {
          delete params["referralFaxNumber"];
        }
      }
      if (!values?.providerEmail || values?.providerEmail === "") {
        delete params["providerEmail"];
      }
      delete params["code"];
      delete params["faxCode"];
      delete params["phoneNumber"];
      delete params["faxNumber"];
      await postApis(providerViewPatientReferralUrl, params)
        .then((response) => {
          setLoading(false);
          setIsModalOpen(false);
          notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        })
        .catch((error) => {
          setLoading(false);
          notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
        });
    }
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const renderProfileCard = () => {
    return (
      <Card
        title={"Profile Details"}
        extra={[
          wellnessInsightsDetails && (
            <Button
              key={"download"}
              icon={<ShareAltOutlined />}
              className="green-btn"
              onClick={showModal}
            >
              Share
            </Button>
          ),
        ]}
        className="box-shadow"
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(FORM_FIELDS.GENDER, profileDetails?.gender)}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(FORM_FIELDS.AGE, profileDetails?.age)}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(FORM_FIELDS.EMAIL, profileDetails?.email)}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(
              FORM_FIELDS.PHONE,
              profileDetails?.phoneNumber
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {renderProfileDetails(FORM_FIELDS.MRN, profileDetails?.mrn)}
          </Col>
        </Row>
      </Card>
    );
  };

  const renderLegends = (item, index) => {
    return (
      <Col key={index} xs={24} sm={24} md={24} lg={12} xl={12}>
        <Flex className="flex-start-center">
          <Popover
            overlayStyle={{
              width: 600,
              maxHeight: 300,
            }}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            }
            trigger="hover"
          >
            <div
              style={{
                backgroundColor: item?.color,
                height: "15px",
                width: "15px",
                borderRadius: "1rem",
                border: "solid 1px #777",
              }}
              className="cursor-pointer"
            ></div>
          </Popover>
          <Popover
            overlayStyle={{
              width: 600,
              maxHeight: 300,
            }}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            }
            trigger="hover"
          >
            <div className="ml1 cursor-pointer">
              {item?.title}{" "}
              <InfoCircleOutlined
                style={{ fontSize: "12px" }}
                className="ml025"
              />
            </div>
          </Popover>
        </Flex>
      </Col>
    );
  };

  return (
    <div>
      <Row gutter={[12, 16]} justify="space-between">
        <Col span={24}>{renderProfileCard()}</Col>
      </Row>
      {wellnessInsightsDetails ? (
        <div>
          <Card
            className="mt2"
            title={
              <Flex className="flex-start-center">
                <img
                  src={patientDashboardImages.WELLNESS}
                  alt={"Risk"}
                  width="45"
                  className="mr05 pdtb05"
                />
                <span className="fs18">Baseline Risk Score</span>
              </Flex>
            }
          >
            <Row gutter={[32, 16]} justify="space-between">
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <img
                  src={wellnessInsightsDetails?.riskClusterUrl}
                  alt={"chart"}
                  width="100%"
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="bold mt05">Legend</div>
                <Divider style={{ margin: "0.25rem 0 1rem 0" }} />
                <Row gutter={[16, 8]} justify="space-between" className="mt1">
                  {content?.audiology?.risk?.legends?.map((item, index) =>
                    renderLegends(item, index)
                  )}
                </Row>
                <div className="bold mt2">Score</div>
                <Divider style={{ margin: "0.25rem 0 1rem 0" }} />

                <BarGraph audiologyDetails={wellnessInsightsDetails} />
              </Col>
            </Row>
          </Card>
        </div>
      ) : (
        <Row justify={"center"}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Empty className="mt3 bold" />
          </Col>
        </Row>
      )}

      {isModalOpen && (
        <Refer
          form={form}
          isModalOpen={isModalOpen}
          handleModalCancel={handleModalCancel}
          loading={loading}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      )}
    </div>
  );
};

export default WellnessInsights;
