import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Form, Modal, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { getApis, postApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS } from "../../../Common/Constants";
import {
  clinicAdminSleepAddLocationUrl,
  clinicAdminSleepDeviceDetailsUrl,
  clinicAdminSleepLocationDetailsUrl,
} from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import useNotify from "../../../Common/Notify";
import { processedPhoneNumber } from "../../../Common/Utils";
import AddLocation from "./AddLocation";
import ViewDevice from "./ViewDevice";

const SleepLocations = () => {
  const { dropdowns } = useSelector((state) => state?.remoteConfig);
  const { showLoader, setShowLoader } = useApiLoader();
  const [sleepLocations, setSleepLocations] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [viewBtnLoading, setViewBtnLoading] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [loadingRowId, setLoadingRowId] = useState(null);
  const { notify } = useNotify();
  const { confirm } = Modal;

  const getSleepLocations = async () => {
    setShowLoader(true);
    await getApis(clinicAdminSleepLocationDetailsUrl, {})
      .then((response) => {
        setShowLoader(false);
        setSleepLocations(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getSleepLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addDevices = async (record) => {
    setAddBtnLoading(true);
    setLoadingRowId(record?.clinicName);
    await postApis(clinicAdminSleepDeviceDetailsUrl, {
      clinicName: record?.clinicName,
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setAddBtnLoading(false);
        setLoadingRowId(null);
      });
  };

  const getDevices = async (record) => {
    setSelectedRow(record);
    setViewBtnLoading(true);
    setLoadingRowId(record?.clinicName);
    await getApis(clinicAdminSleepDeviceDetailsUrl, {
      clinicName: record?.clinicName,
    })
      .then((response) => {
        setDeviceDetails(response?.data);
        setIsViewModalOpen(true);
        setViewBtnLoading(false);
      })
      .catch(() => {
        setViewBtnLoading(false);
        setLoadingRowId(null);
      });
  };

  const showAddModal = () => {
    setIsAddModalOpen(true);
    form.resetFields();
  };

  const handleAddModelCancel = () => {
    setIsAddModalOpen(false);
  };

  const handleViewModelCancel = () => {
    setIsViewModalOpen(false);
    setLoadingRowId(null);
  };

  const showAddConfirm = (item) => {
    confirm({
      title: "Are you sure?",
      content: "This action will add a new device to this location.",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        addDevices(item);
      },
      onCancel() {},
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    const phone = await processedPhoneNumber(values);

    await postApis(clinicAdminSleepAddLocationUrl, {
      ...values,
      phoneNumber: phone,
    })
      .then((response) => {
        setLoading(false);
        setIsAddModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const columns = [
    {
      title: "Clinic",
      dataIndex: "clinicName",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Total Devices",
      dataIndex: "totalDevices",
    },
    {
      title: "Rented Devices",
      dataIndex: "devicesGiven",
    },
    {
      title: "Staff Name",
      dataIndex: "staffName",
    },
    {
      title: "Devices",
      render: (_, record) => (
        <div className="flex-center">
          <Button
            size="small"
            className="green-btn mr05"
            onClick={() => showAddConfirm(record)}
            disabled={loadingRowId === record.clinicName}
          >
            <Loader
              loading={addBtnLoading && loadingRowId === record.clinicName}
              text={
                <>
                  <PlusOutlined style={{ color: "#fff" }} /> {BUTTONS.ADD}
                </>
              }
            />
          </Button>
          <Button
            size="small"
            onClick={() => getDevices(record)}
            disabled={loadingRowId === record.clinicName}
          >
            <Loader
              loading={viewBtnLoading && loadingRowId === record.clinicName}
              text={
                <>
                  <EyeOutlined className="mr05" />
                  {BUTTONS.VIEW}
                </>
              }
            />
          </Button>
        </div>
      ),
    },
  ];

  return (
    !showLoader && (
      <div data-testid="clinicAdmin-sleepLocations">
        {sleepLocations ? (
          <>
            {false && (
              <Row justify={"end"} gutter={[16, 8]}>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Button
                    type="primary"
                    className="full-width btn-extra-pd fs16"
                    onClick={showAddModal}
                  >
                    <PlusOutlined /> Add Location
                  </Button>
                </Col>
              </Row>
            )}
            <div className="mt1">
              <Table
                rowKey={(record) => record.clinicName}
                columns={columns}
                dataSource={sleepLocations}
                scroll={{ x: "100%" }}
                pagination={false}
              />
            </div>
            <AddLocation
              form={form}
              isAddModalOpen={isAddModalOpen}
              handleAddModelCancel={handleAddModelCancel}
              loading={loading}
              onFinish={onFinish}
              staticDropdownData={dropdowns}
              onFinishFailed={onFinishFailed}
            />
            <ViewDevice
              modalOpen={isViewModalOpen}
              cancelModal={handleViewModelCancel}
              deviceDetails={deviceDetails}
              selectedRow={selectedRow}
            />
          </>
        ) : (
          <Card>
            <Empty />
          </Card>
        )}
      </div>
    )
  );
};

export default SleepLocations;
