import { Button, Card, Result } from "antd";
import { ROUTES } from "./Common/Constants";

const AccessDenied = () => {
  return (
    <Card>
      <Result
        status="403"
        title="Access Restricted"
        subTitle="Please contact the clinic administrator to restore your access."
        extra={
          <Button
            type="primary"
            onClick={() => window.location.assign(ROUTES.LANDING.PATH)}
          >
            Go Home
          </Button>
        }
      />
    </Card>
  );
};

export default AccessDenied;
