import { Col, Row } from "antd";
import { FORM_FIELDS } from "../../Common/Constants";

const BasicDetails = ({
  profileDetails,
  renderProfileDetails,
  defaultDoctor,
}) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        {renderProfileDetails(FORM_FIELDS.NAME, profileDetails?.fullName)}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        {renderProfileDetails(
          FORM_FIELDS.EMAIL_ADDRESS,
          profileDetails?.email || "Not Available"
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        {renderProfileDetails(
          FORM_FIELDS.GENDER,
          profileDetails?.gender || "Not Available"
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        {renderProfileDetails(
          FORM_FIELDS.ROLE,
          profileDetails?.role || "Not Available"
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        {renderProfileDetails(
          FORM_FIELDS.CLINIC,
          profileDetails?.clinic || "Not Available"
        )}
      </Col>
      {defaultDoctor && (
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {renderProfileDetails(
            FORM_FIELDS.PROVIDER,
            profileDetails?.defaultDoctor
          )}
        </Col>
      )}
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        {renderProfileDetails(
          FORM_FIELDS.PROGRAMS,
          profileDetails?.programs || "Not Available"
        )}
      </Col>
    </Row>
  );
};

export default BasicDetails;
