import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Row, Button, Form, Empty, Flex, Modal } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { deleteApis, getApis, postApis, putApis } from "../../../Common/Apis";
import { NOTIFICATIONS } from "../../../Common/Constants";
import {
  clinicAdminSettingsProductUrl,
  clinicAdminSettingsUrl,
} from "../../../Common/Endpoints";
import useNotify from "../../../Common/Notify";
import Product from "./Product";
import ProgramsQR from "./ProgramsQR";

const AccountSettings = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const clinicAdminMenus = JSON.parse(localStorage.getItem("clinicAdminMenus"));
  const { images } = useSelector((state) => state?.remoteConfig);
  const image = images?.clinicAdmin?.account?.settings;
  const [accountDetails, setAccountDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQRListModalOpen, setIsQRListModalOpen] = useState(false);
  const [formTitle, setFormTitle] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [form] = Form.useForm();
  const { notify } = useNotify();
  const { confirm } = Modal;

  const getAccountDetails = async () => {
    setShowLoader(true);
    await getApis(clinicAdminSettingsUrl)
      .then((response) => {
        setShowLoader(false);
        setAccountDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = (record) => {
    form.resetFields();
    setIsModalOpen(true);
    if (record) {
      setSelectedRow(record);
      setFormTitle("Edit Product");
      form.setFieldsValue({
        ...record,
      });
    } else {
      setSelectedRow();
      setFormTitle("Add Product");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showQRListModal = () => {
    setIsQRListModalOpen(true);
  };

  const handleQRListModalCancel = () => {
    setIsQRListModalOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    selectedRow
      ? await putApis(clinicAdminSettingsProductUrl, {
          ...values,
          id: selectedRow?.id,
        })
          .then((response) => {
            setLoading(false);
            notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            setLoading(false);
            notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
          })
      : await postApis(clinicAdminSettingsProductUrl, values)
          .then((response) => {
            setLoading(false);
            notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            setLoading(false);
            notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
          });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const showConfirm = (record) => {
    confirm({
      title: "Are you sure?",
      content: "This action will remove a product.",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        deleteProduct(record);
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const deleteProduct = async (record) => {
    await deleteApis(clinicAdminSettingsProductUrl, {
      id: record?.id,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) =>
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message)
      );
  };

  return (
    !showLoader && (
      <>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Card
              title={"Available Kits"}
              extra={[
                <Button
                  key="add"
                  type="primary"
                  onClick={() => showQRListModal()}
                  icon={<PlusOutlined />}
                >
                  Add
                </Button>,
              ]}
            >
              {(!accountDetails || !accountDetails?.kits) && (
                <Empty
                  description="Please contact support@saigeware.com to request your kits."
                  imageStyle={{
                    height: 70,
                  }}
                />
              )}
              {accountDetails?.kits?.map((item, index) => {
                return (
                  <div key={index} className="grey-fill pd1">
                    <Row gutter={[16, 16]}>
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={8}
                        className="flex-start-center"
                      >
                        <img alt={item?.name} src={image?.kit} width="100%" />
                      </Col>
                      <Col xs={18} sm={18} md={18} lg={18} xl={16}>
                        <div className="bold fs18 blue flex-start-center">
                          {item?.name}
                        </div>
                        <div className="mt1">
                          <span className="bold">Kit Provided On:</span>{" "}
                          <span>{item?.date}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Card>
          </Col>
        </Row>
        {accountDetails?.kits && (
          <Card
            title={"Products"}
            className="mt1"
            extra={[
              <Button
                key="add"
                type="primary"
                onClick={() => showModal()}
                icon={<PlusOutlined />}
              >
                Add
              </Button>,
            ]}
          >
            {accountDetails?.products ? (
              <>
                <div>
                  If you would like SAiWELL to charge patients for their
                  GT+insights, add the amount that needs to be charged here.
                </div>
                <Row gutter={[16, 16]} className="mt2">
                  {accountDetails?.products?.map((item, index) => {
                    return (
                      <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Card
                          title={item?.product}
                          className="box-shadow"
                          actions={[
                            <Link
                              key="edit"
                              onClick={() => showModal(item)}
                              size="small"
                            >
                              <EditOutlined /> Edit
                            </Link>,
                            <Link
                              key="delete"
                              onClick={() => showConfirm(item)}
                              size="small"
                            >
                              <DeleteOutlined /> Delete
                            </Link>,
                          ]}
                        >
                          {item?.subscriptionAmount && (
                            <Flex className="mt05">
                              <span className="mr05 grey">
                                Subscription Amount:
                              </span>
                              ${item?.subscriptionAmount}
                            </Flex>
                          )}
                          {item?.oneTimeAmount && (
                            <Flex className="mt05">
                              <span className="mr05 grey">
                                One Time Amount:
                              </span>
                              ${item?.oneTimeAmount}
                            </Flex>
                          )}
                          {item?.durationInMonth && (
                            <Flex className="mt05">
                              <span className="mr05 grey">
                                Duration In Months:
                              </span>
                              {item?.durationInMonth}
                            </Flex>
                          )}
                          {item?.lifetimeSubscription && (
                            <Flex className="mt05">
                              <span className="mr05 grey">
                                Life Time Subscription:
                              </span>
                              {item?.lifetimeSubscription ? "Yes" : "No"}
                            </Flex>
                          )}
                          {item?.paymentType && (
                            <Flex className="mt05">
                              <span className="mr05 grey">Payment Type:</span>
                              {item?.paymentType}
                            </Flex>
                          )}
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </>
            ) : (
              <Empty
                description="If you would like SAiWELL to charge patients for their
              GT+insights, add the amount that needs to be charged here."
              />
            )}
          </Card>
        )}

        {isModalOpen && (
          <Product
            formTitle={formTitle}
            form={form}
            selectedRow={selectedRow}
            loading={loading}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        )}
        {isQRListModalOpen && (
          <ProgramsQR
            userDetails={userDetails}
            programs={clinicAdminMenus}
            isQRListModalOpen={isQRListModalOpen}
            handleQRListModalCancel={handleQRListModalCancel}
          />
        )}
      </>
    )
  );
};

export default AccountSettings;
