import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import { useState } from "react";
import { postApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../../Common/Constants";
import { providerViewPatientAudiologyGtDataUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import useNotify from "../../../Common/Notify";
import {
  disableFutureDates,
  renderKeyValueDetails,
} from "../../../Common/Utils";

const Cognivue = ({ audiologyGtDetails, profileDetails, isMobile }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();

  const onFinish = async (values) => {
    const params = {
      ...values,
      token: profileDetails?.token,
      type: "Cognivue",
      TestDate: values?.TestDate?.format("MM/DD/YYYY"),
    };
    setLoading(true);
    await postApis(providerViewPatientAudiologyGtDataUrl, params)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return Object.keys(audiologyGtDetails?.cognivue)?.length > 0 ? (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          "Memory",
          audiologyGtDetails?.cognivue?.Memory
        )}
        {renderKeyValueDetails(
          isMobile,
          "Processing Speed(ms)",
          audiologyGtDetails?.cognivue?.ProcessingSpeed
        )}
        {renderKeyValueDetails(
          isMobile,
          "Reaction Time(ms)",
          audiologyGtDetails?.cognivue?.ReactionTime
        )}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          "Executive Function",
          audiologyGtDetails?.cognivue?.ExecutiveFunction
        )}
        {renderKeyValueDetails(
          isMobile,
          "Test Date",
          audiologyGtDetails?.cognivue?.TestDate
        )}
        {renderKeyValueDetails(
          isMobile,
          "Visuospatial",
          audiologyGtDetails?.cognivue?.VisuoSpatial
        )}
      </Col>
    </Row>
  ) : (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={{
        required: VALIDATIONS.DEFAULT.BLANK,
      }}
    >
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="Memory"
            name="Memory"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (!isNaN(value) && value >= 0 && value <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Invalid input (0 to 100)");
                },
              },
            ]}
          >
            <Input placeholder="100" max={100} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="Reaction Time(ms)"
            name="ReactionTime"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (!isNaN(value) && value >= 0 && value <= 2000) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Invalid percentage (0 to 2000)");
                },
              },
            ]}
          >
            <Input placeholder="0-2000" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="VisuoSpatial"
            name="VisuoSpatial"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (!isNaN(value) && value >= 0 && value <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Invalid percentage (0 to 100)");
                },
              },
            ]}
          >
            <Input placeholder="100" max={100} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="Processing Speed(ms)"
            name="ProcessingSpeed"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (!isNaN(value) && value >= 0 && value <= 2500) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Invalid percentage (0 to 2500)");
                },
              },
            ]}
          >
            <Input placeholder="0-2500" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="Executive Function"
            name="ExecutiveFunction"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="100" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="Test Date"
            name="TestDate"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              placeholder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              inputReadOnly
              disabledDate={disableFutureDates}
              allowClear={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="flex-center">
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item className="mt1">
            <Button
              id="cognivue"
              type="primary"
              htmlType="submit"
              block
              disabled={loading}
            >
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Cognivue;
