import { Button, Card, Col, Empty, Form, Input, Row } from "antd";
import { useState } from "react";
import { postApis } from "../../../Common/Apis";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROLES,
  VALIDATIONS,
} from "../../../Common/Constants";
import { providerViewPatientAudiologyRemarksUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import useNotify from "../../../Common/Notify";
const { TextArea } = Input;

const Remarks = ({ profileDetails, remarksDetails, role }) => {
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [form] = Form.useForm();
  const { notify } = useNotify();

  const editHandler = () => {
    setShowForm(true);
    form.setFieldsValue({ ...remarksDetails });
  };

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(providerViewPatientAudiologyRemarksUrl, {
      remarks: values?.remarks?.trim(),
      token: profileDetails?.token,
    })
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <Card
      title={"Your Remarks"}
      className="box-shadow mt1"
      extra={[
        remarksDetails?.remarks && (
          <Button key="edit" type="primary" onClick={editHandler}>
            Edit
          </Button>
        ),
      ]}
    >
      {remarksDetails && !showForm ? (
        <div>{remarksDetails?.remarks}</div>
      ) : role !== ROLES.STAFF ? (
        <Form
          initialValues={{ remarks: remarksDetails?.remarks }}
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
        >
          <Form.Item
            name="remarks"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={3} placeholder="Description" />
          </Form.Item>
          <Row className="flex-center mt1">
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Button
                id="remarks"
                type="primary"
                htmlType="submit"
                block
                disabled={loading}
              >
                <Loader loading={loading} text={BUTTONS.SUBMIT} />
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Empty description="Only provider can add remarks" />
      )}
    </Card>
  );
};

export default Remarks;
