import { Card, Col, Divider, Empty, Flex, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { getApis } from "../../Common/Apis";
import { patientWeatherUrl } from "../../Common/Endpoints";
import { displayDateTime } from "../../Common/Utils";
import SUMMARY from "../../../Assets/images/Weather/summary.svg";
import HUMID from "../../../Assets/images/Weather/humid.svg";
import DEW from "../../../Assets/images/Weather/dew.svg";
import LAT from "../../../Assets/images/Weather/lat.svg";
import LONG from "../../../Assets/images/Weather/long.svg";
import INTENSITY from "../../../Assets/images/Weather/intensity.svg";
import PROBABILITY from "../../../Assets/images/Weather/probability.svg";
import TEMP from "../../../Assets/images/Weather/temp.svg";
import WIND from "../../../Assets/images/Weather/wind.svg";
import GRASS from "../../../Assets/images/Weather/grass.svg";
import TREE from "../../../Assets/images/Weather/tree.svg";
import WEED from "../../../Assets/images/Weather/weed.svg";
import AQI from "../../../Assets/images/Weather/aqi.svg";
import SO2 from "../../../Assets/images/Weather/so2.svg";
import CO from "../../../Assets/images/Weather/co.svg";
import PM10 from "../../../Assets/images/Weather/pm10.svg";
import PM25 from "../../../Assets/images/Weather/pm25.svg";
import POLLUTANT from "../../../Assets/images/Weather/pollutant.svg";
import NO2 from "../../../Assets/images/Weather/no2.svg";
import OZONE from "../../../Assets/images/Weather/ozone.svg";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import BlueBanner from "../../Common/BlueBanner";
const { Title } = Typography;

const Weather = () => {
  const [allDetails, setAllDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();

  const getWeather = async () => {
    setShowLoader(true);
    await getApis(patientWeatherUrl)
      .then((response) => {
        setShowLoader(false);
        setAllDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const imageMap = {
    "Dew Point": DEW,
    Humidity: HUMID,
    Latitude: LAT,
    Longitude: LONG,
    "Precipitation Intensity": INTENSITY,
    "Precipitation Probability": PROBABILITY,
    Temperature: TEMP,
    "Wind Speed": WIND,
    Grass: GRASS,
    Tree: TREE,
    Weed: WEED,
    AQI: AQI,
    "Major Pollutant": POLLUTANT,
    CO: CO,
    "NO₂": NO2,
    OZONE: OZONE,
    "PM₁₀": PM10,
    "PM₂₅": PM25,
    "SO₂": SO2,
  };

  useEffect(() => {
    getWeather();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCards = (key, span, item, imgSpan) => {
    return (
      <Col key={key} xs={24} sm={12} md={12} lg={8} xl={span}>
        <Card
          className="box-more-shadow"
          styles={{
            body: {
              padding: "1rem",
            },
          }}
        >
          <Row justify={"space-between"}>
            <Col span={16}>
              <Flex wrap="wrap" gap="small" className="flex-baseline">
                <div className="fs30 bold">{item?.value}</div>
                <div>{item?.units}</div>
              </Flex>
            </Col>
            <Col span={imgSpan ? imgSpan : 7}>
              <img src={imageMap[item.label]} alt={item.label} width="100%" />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="fs13 bold grey">{item?.label}</div>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  const noData = () => {
    return (
      <Card>
        <Empty />
      </Card>
    );
  };

  return (
    !showLoader && (
      <div data-testid="weather" className="mb1">
        {allDetails && Object.entries(allDetails).length !== 0 ? (
          <>
            {allDetails?.weather?.length === 0 ? (
              noData()
            ) : (
              <Row justify="center">
                <Col span={24}>
                  <BlueBanner image={SUMMARY} span={4}>
                    <div className="fs24 bold">Summary</div>
                    <p>{allDetails?.summary}</p>
                    <div className="bold">
                      <span className="mr05">
                        <ClockCircleOutlined />
                      </span>
                      <span>{displayDateTime(allDetails?.created)}</span>
                    </div>
                  </BlueBanner>
                  <Row gutter={[32, 32]} justify="center" className="mt2">
                    {allDetails?.weather?.map((item, index) => {
                      return renderCards(index, 6, item);
                    })}
                  </Row>
                </Col>
              </Row>
            )}

            <div className="mt3">
              <Title level={3}>Pollen Counts</Title>
              <Divider style={{ margin: "0 0px 1.5rem" }} />
            </div>

            {allDetails?.pollen?.length === 0 ? (
              noData()
            ) : (
              <Row gutter={[32, 32]} justify="center" className="mt1">
                {allDetails?.pollen?.map((item, index) => {
                  return renderCards(index, 8, item, 5);
                })}
              </Row>
            )}

            <div className="mt3">
              <Title level={3}>Air Quality</Title>
              <Divider style={{ margin: "0 0px 1.5rem" }} />
            </div>

            {allDetails?.airQuality?.length === 0 ? (
              noData()
            ) : (
              <Row gutter={[32, 32]} justify="center" className="mt1">
                {allDetails?.airQuality?.map((item, index) => {
                  return renderCards(index, 6, item);
                })}
              </Row>
            )}
          </>
        ) : (
          noData()
        )}
      </div>
    )
  );
};

export default Weather;
