import { Checkbox, Col, Form, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingDetails,
  setTimeSlots,
} from "../../../../Reducers/bookingsSlice";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { getApis } from "../../../Common/Apis";
import { BUTTONS, FORM_FIELDS, VALIDATIONS } from "../../../Common/Constants";
import {
  bookingsLocationsUrl,
  patientFotonaBookingsUrl,
} from "../../../Common/Endpoints";
import { centalisedSubmitBtn } from "../../../Common/Utils";

const Location = ({ setCurrentStep, activeDate }) => {
  const [locations, setLocations] = useState();
  const [loading, setLoading] = useState(false);
  const bookings = useSelector((state) => state?.bookings);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const { showLoader, setShowLoader } = useApiLoader();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocations = async () => {
    setShowLoader(true);
    await getApis(bookingsLocationsUrl, { type: "fotona" })
      .then((response) => {
        setShowLoader(false);
        setLocations(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const onFinish = async (values) => {
    setLoading(true);
    const updatedValues = {
      ...values,
      bookingDate: activeDate.format("YYYY-MM-DD"),
      paymentStatus: values?.paymentStatus ? "Self Pay" : "Insurance",
    };
    dispatch(setBookingDetails(updatedValues));

    await getApis(patientFotonaBookingsUrl, updatedValues)
      .then((response) => {
        setLoading(false);
        dispatch(setTimeSlots(response?.data));
        setCurrentStep(1);
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    !showLoader && (
      <Row justify={"center"} className="mt2" data-testid="fotona-locations">
        <Col span={24} className="flex-center">
          <Form
            form={form}
            initialValues={{
              location: bookings?.details?.location,
            }}
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            validateMessages={{
              required: VALIDATIONS.DEFAULT.BLANK,
            }}
            style={{ width: isMobile ? "100%" : 500 }}
          >
            <Form.Item
              label={FORM_FIELDS.LOCATION}
              name="location"
              size="large"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                name="location"
                size="large"
                placeholder="Please Select"
                options={locations}
                onChange={() => {}}
              />
            </Form.Item>

            <div className="bold mb1">
              Usually this therapy contains 3 sessions. Also you can
              choose only one session here.
            </div>
            <Form.Item name="singleSession" valuePropName="checked">
              <Checkbox>Select Single Session</Checkbox>
            </Form.Item>

            <Form.Item>
              {centalisedSubmitBtn(loading, BUTTONS.NEXT, null, 10)}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  );
};
export default Location;
