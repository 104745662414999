import { Button, Card, Col, Divider, Empty, Row, Spin, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingDetails,
  setMailSent,
  setTimeSlots,
} from "../../../../Reducers/bookingsSlice";
import { getApis, postApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../../Common/Constants";
import {
  patientBookingsSendDocumentUrl,
  patientBookingsUrl,
} from "../../../Common/Endpoints";
import MonthlyCalendar from "../../../Common/MonthlyCalendar";
import useNotify from "../../../Common/Notify";
import {
  centalisedSubmitBtn,
  disabledBookingsDate,
  getBaseUrl,
  getOnlyWeekDays,
} from "../../../Common/Utils";

const TimeSlots = ({ currentStep, activeDate, setCurrentStep }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const bookings = useSelector((state) => state?.bookings);
  const bookingDetails =
    bookings?.details || JSON.parse(localStorage.getItem("bookingDetails"));
  const timeSlots = bookings?.timeSlots;
  const [amTimes, setAmTimes] = useState([]);
  const [pmTimes, setPmTimes] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const currentDate = activeDate || moment().add(1, "days");
  const [loading, setLoading] = useState(false);
  const resultDate = getOnlyWeekDays(currentDate, 2);
  const { notify } = useNotify();
  const [returnDate, setReturnDate] = useState(
    resultDate?.format("YYYY-MM-DD")
  );
  const [values, setValues] = useState({
    bookingDate: currentDate.format("YYYY-MM-DD"),
    slot: null,
  });
  const [errors, setErrors] = useState({
    bookingDate: false,
    slot: false,
  });

  useEffect(() => {
    const am = timeSlots?.filter((time) => time.includes("AM"));
    const pm = timeSlots?.filter((time) => time.includes("PM"));

    setAmTimes(am);
    setPmTimes(pm);
  }, [timeSlots]);

  const validateFields = () => {
    const newErrors = {
      bookingDate: values.bookingDate === null,
      slot: values.slot === null,
    };
    setErrors(newErrors);
    return !newErrors.bookingDate && !newErrors.slot;
  };

  const submitHandler = async () => {
    setLoading(true);
    if (validateFields()) {
      await postApis(patientBookingsSendDocumentUrl, {
        ...bookingDetails,
        returnDate: returnDate,
        name: `${userDetails?.firstName} ${userDetails?.lastName}`,
        email: userDetails?.email,
        host: getBaseUrl(),
      })
        .then((response) => {
          if (response?.data?.status === "Failed") {
            notify(NOTIFICATIONS.ERROR.KEY, response?.data?.message);
            setLoading(false);
          } else {
            dispatch(
              setBookingDetails({
                ...bookingDetails,
                returnDate: returnDate,
                signData: response?.data?.signData,
                signedStatus: response?.data?.status,
                sentAt: new Date().getTime(),
              })
            );
            dispatch(setMailSent(response?.data?.status === "Sent"));
            setLoading(false);
            setCurrentStep(currentStep + 1);
          }
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  const getAvailableSlots = async (params) => {
    await getApis(patientBookingsUrl, params)
      .then((response) => {
        setShowLoader(false);
        dispatch(setTimeSlots(response?.data));
        setValues({ ...values, slot: null });
      })
      .catch(() => {
        dispatch(setTimeSlots([]));
        setShowLoader(false);
      });
  };

  const handleDateSelect = async (date) => {
    setShowLoader(true);
    const resultDate = getOnlyWeekDays(
      date.format("YYYY-MM-DD"),
      bookingDetails?.days
    );
    setReturnDate(resultDate.format("YYYY-MM-DD"));
    const updatedValues = {
      ...bookingDetails,
      ...values,
      bookingDate: date.format("YYYY-MM-DD"),
      returnDate: resultDate.format("YYYY-MM-DD"),
    };
    setValues(updatedValues);
    dispatch(setBookingDetails(updatedValues));
    getAvailableSlots(updatedValues);
  };

  const handleTagClick = (time) => {
    dispatch(setBookingDetails({ ...bookingDetails, slot: time }));
    setValues({ ...values, slot: time });
    setErrors({ ...errors, slot: false });
  };

  return (
    <div data-testid="timeslots">
      <Row gutter={[16, 16]} className="mt3">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card
            className="box-shadow"
            styles={{
              body: {
                padding: "0.5rem 1rem",
              },
            }}
          >
            <MonthlyCalendar
              handleDateSelect={handleDateSelect}
              disabledDate={(current) =>
                disabledBookingsDate(current, bookingDetails?.weeks)
              }
            />
            {errors.bookingDate && (
              <p style={{ color: "red" }}>{VALIDATIONS.DEFAULT.BLANK}</p>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card
            className="box-shadow"
            title="Available Slots"
            styles={{
              body: {
                minHeight: "330px",
                height: "330px",
                overflow: "auto",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              },
            }}
          >
            <div className="flex-center">
              {showLoader ? (
                <Spin size="large" />
              ) : timeSlots?.length > 0 ? (
                <div>
                  <div className="bold blue">Morning</div>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  {amTimes?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        className={`mt1 ml05 cursor-pointer ${
                          values.slot === item ? "selected-tag" : ""
                        }`}
                        onClick={() => handleTagClick(item)}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                  <div className="bold blue mt2">Afternoon</div>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  {pmTimes?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        className={`mt1 ml05 cursor-pointer ${
                          values.slot === item ? "selected-tag" : ""
                        }`}
                        onClick={() => handleTagClick(item)}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </Card>
          {errors.slot && (
            <div style={{ color: "red" }} className="mt05">
              {VALIDATIONS.DEFAULT.BLANK}
            </div>
          )}
        </Col>
      </Row>
      <Row className="mt1">
        <Col span={24}>
          <p>
            <b>Note:</b> Each time slot is valid for 30 minutes only.
          </p>
          <p>
            Please sign the lease agreement in the next step to proceed further.
          </p>
        </Col>
      </Row>
      {centalisedSubmitBtn(loading, BUTTONS.NEXT, submitHandler, 4)}
      <div className="flex-center mt1">
        <Button size="small" onClick={() => setCurrentStep(currentStep - 1)}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default TimeSlots;
