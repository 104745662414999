import React, { useState } from "react";
import { Upload, Button, Card, Row, Col, Avatar } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { clinicAdminProfileMetaUrl } from "../../Common/Endpoints";
import { postApis } from "../../Common/Apis";
import Loader from "../../Common/Loader";
import { BUTTONS, NOTIFICATIONS } from "../../Common/Constants";
import useNotify from "../../Common/Notify";

const AddLogo = ({ metaDetails }) => {
  const [imageBase64, setImageBase64] = useState();
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = async ({ file }) => {
    if (!file) return;

    if (file.type !== "image/svg+xml") {
      notify(NOTIFICATIONS.ERROR.KEY, "Please upload only SVG images.");
      return;
    }

    try {
      const base64 = await getBase64(file);
      setImageBase64(base64);
    } catch (error) {
      notify(NOTIFICATIONS.ERROR.KEY, "Failed to convert SVG.");
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!imageBase64) {
      notify(NOTIFICATIONS.ERROR.KEY, "Please select an image to upload.");
      return;
    }

    await postApis(clinicAdminProfileMetaUrl, { image: imageBase64 })
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) =>
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message)
      );
  };

  return (
    <Card title={"Add/Edit Logo"}>
      {metaDetails?.logoUrl && (
        <div className="mb1">
          If you have changed the logo recently, it might take some time to
          reflect here
        </div>
      )}
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={24} md={12} lg={10} xl={8} className="flex-center">
          {metaDetails?.logoUrl ? (
            <img src={metaDetails?.logoUrl} alt="SVG Preview" width="100%" />
          ) : (
            <Avatar
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 100,
                xxl: 100,
              }}
            >
              C
            </Avatar>
          )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Upload
            beforeUpload={() => false}
            onChange={handleChange}
            accept=".svg"
          >
            <Button icon={<UploadOutlined />}>Select New SVG Image</Button>
          </Upload>
          <Button
            size="large"
            className="mt2"
            type="primary"
            onClick={handleUpload}
            disabled={!imageBase64 || loading}
          >
            <Loader loading={loading} text={BUTTONS.SAVE} />
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddLogo;
