import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Result,
  Row,
  Space,
} from "antd";
import { useState } from "react";
import {
  BUTTONS,
  FORM_FIELDS,
  SERVICES,
  VALIDATIONS,
} from "../../Common/Constants";

import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";

const SleepQuestionnaireForm = ({
  userDetails,
  isModalOpen,
  questionnaireRawDetails,
  handleCancel,
  loading,
  onFinish,
  onFinishFailed,
  sleepQuestionnaireDetails,
}) => {
  const { TextArea } = Input;

  const [showReasonField, setShowReasonField] = useState(false);

  const handleOptions = (option, question) => {
    if (question === "QA1" && option?.label === "Other (Please specify)") {
      setShowReasonField(true);
    } else if (
      question === "QA1" &&
      option?.label !== "Other (Please specify)"
    ) {
      setShowReasonField(false);
    }
  };

  return (
    <Popup
      title={"Fill Questionnaires"}
      open={isModalOpen}
      cancel={handleCancel}
      footer={null}
      width={900}
      style={{ top: "25px" }}
    >
      {sleepQuestionnaireDetails?.map((apiData, apiIndex) => {
        return apiData?.submitted ? (
          <Result
            key={apiIndex}
            icon={false}
            title={`${
              questionnaireRawDetails?.[apiData?.subType]?.title
            } has already been submitted`}
          />
        ) : (
          <div key={apiIndex}>
            <div className="bold fs16">
              {questionnaireRawDetails?.[apiData?.subType]?.title}
            </div>
            <div className="mt1">
              {questionnaireRawDetails?.[apiData?.subType]?.description}
            </div>
            <Form
              name={"ring"}
              initialValues={{
                clinic: "SFOTO",
                service: SERVICES.SLEEP,
                type: apiData?.type,
                subType: apiData?.subType,
                feet: userDetails?.feet,
                inches: userDetails?.inches,
                lbsWeight: userDetails?.lbsWeight,
                sfotoSleepQuestionnaire: true,
              }}
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="mt2"
              validateMessages={{
                required: VALIDATIONS.DEFAULT.BLANK,
              }}
            >
              <Form.Item name="clinic" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="service" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="type" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="subType" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="sfotoSleepQuestionnaire" hidden>
                <Input />
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                  <Form.Item
                    label="Height in Feet"
                    name="feet"
                    rules={[
                      {
                        required: true,
                        message: VALIDATIONS.DEFAULT.BLANK,
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Feet"
                      min={1}
                      step={1}
                      max={12}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                  <Form.Item
                    label="Height in Inches"
                    name="inches"
                    rules={[
                      {
                        required: true,
                        message: VALIDATIONS.DEFAULT.BLANK,
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Inches"
                      min={0}
                      step={1}
                      max={11}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                  <Form.Item
                    label={FORM_FIELDS.LBS_WEIGHT}
                    name="lbsWeight"
                    size="large"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input type="number" placeholder="150" min={1} step={1} />
                  </Form.Item>
                </Col>
              </Row>

              {questionnaireRawDetails?.[apiData?.subType]?.questionnaires?.map(
                (questionnaire, index) => {
                  return (
                    <div key={index} className="mt1">
                      <div className="bold mb1">{questionnaire?.label}</div>
                      {questionnaire?.questions?.map((question, index) => {
                        return (
                          <div key={index}>
                            <Form.Item
                              label={question?.title}
                              size="large"
                              name={question?.value}
                              className="flex-start-center center-radio mt1"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select an option",
                                },
                              ]}
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                            >
                              <Radio.Group className="m0">
                                <Space direction={"vertical"}>
                                  {questionnaire?.options?.map((opt, index) => {
                                    return (
                                      <Radio
                                        key={index}
                                        onChange={(e) => {
                                          handleOptions(opt, question?.value);
                                        }}
                                        value={opt?.value}
                                      >
                                        {opt?.label}
                                      </Radio>
                                    );
                                  })}
                                </Space>
                              </Radio.Group>
                            </Form.Item>

                            {question?.value === "QA1" && showReasonField && (
                              <Form.Item
                                name={`${question?.value}Reason`}
                                dependencies={[question?.value]}
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      const selectedOption = getFieldValue(
                                        question?.value
                                      );
                                      if (selectedOption === "3" && !value) {
                                        return Promise.reject(
                                          new Error(VALIDATIONS.DEFAULT.BLANK)
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <TextArea rows={3} placeholder="Description" />
                              </Form.Item>
                            )}
                          </div>
                        );
                      })}
                      <Divider />
                    </div>
                  );
                }
              )}
              <Row justify={"center"}>
                <Col xs={12} sm={8} md={8} lg={4} xl={4}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={loading}
                    className="mt1"
                  >
                    <Loader loading={loading} text={BUTTONS.SUBMIT} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        );
      })}
    </Popup>
  );
};

export default SleepQuestionnaireForm;
