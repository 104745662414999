import Popup from "../Popup";

const ViewQuestionnaires = ({
  questionnaireModalOpen,
  cancelQuestionnairesModal,
  questionnaireMappedData,
}) => {
  return (
    <Popup
      title="Questionnaires"
      open={questionnaireModalOpen}
      cancel={cancelQuestionnairesModal}
      footer={null}
      width={800}
      style={{ top: "25px" }}
    >
      {questionnaireMappedData
        ?.filter((i) => i?.value !== "QA1Reason")
        ?.map((item, index) => {
          return (
            <div key={index} className="mb1">
              <div>
                {index + 1}. {item?.title}
              </div>
              <div>
                Answer: <b>{item?.selectedOption}</b>
              </div>
            </div>
          );
        })}
    </Popup>
  );
};

export default ViewQuestionnaires;
