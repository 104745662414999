import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Empty, Flex, Popover, Row } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis } from "../../Common/Apis";
import { patientAudiologyUrl } from "../../Common/Endpoints";
import { providerViewPatient } from "../../Common/Images";
import BarGraph from "./BarGraph";

const Audiology = () => {
  const { contents } = useSelector((state) => state?.remoteConfig);
  const content = contents?.provider?.viewPatient;
  const { showLoader, setShowLoader } = useApiLoader();
  const [audiologyDetails, setAudiologyDetails] = useState();
  const isMobile = useSelector((state) => state?.app?.isMobile);

  const getData = async () => {
    setShowLoader(true);
    await getApis(patientAudiologyUrl)
      .then((response) => {
        setShowLoader(false);
        setAudiologyDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLegends = (item, index) => {
    return (
      <Col key={index} xs={24} sm={24} md={24} lg={12} xl={12}>
        <Flex className="flex-start-center">
          <Popover
            overlayStyle={{
              width: 600,
              maxHeight: 300,
            }}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            }
            trigger="hover"
          >
            <div
              style={{
                backgroundColor: item?.color,
                height: "15px",
                width: "15px",
                borderRadius: "1rem",
                border: "solid 1px #777",
              }}
              className="cursor-pointer"
            ></div>
          </Popover>
          <Popover
            overlayStyle={{
              width: 600,
              maxHeight: 300,
            }}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            }
            trigger="hover"
          >
            <div className="ml1 cursor-pointer">
              {item?.title}{" "}
              <InfoCircleOutlined
                style={{ fontSize: "12px" }}
                className="ml025"
              />
            </div>
          </Popover>
        </Flex>
      </Col>
    );
  };

  return (
    !showLoader &&
    (audiologyDetails ? (
      <Row gutter={[12, 16]} justify="center" className="mt1">
        <Col span={24}>
          <Card
            title={
              <Flex wrap="wrap" align={"center"}>
                <img
                  src={providerViewPatient.RISK}
                  alt={"Risk"}
                  width={isMobile ? "25" : "45"}
                  className="mr05 pdtb05"
                />
                <span className={isMobile && "fs16"}>
                  Baseline Audiology Risk Clustering
                </span>
              </Flex>
            }
            extra={[
              !isMobile && (
                <Button
                  type="primary"
                  size="small"
                  key={"download"}
                  icon={<EyeOutlined />}
                  onClick={() =>
                    window.open(audiologyDetails?.reportUrl, "_blank")
                  }
                >
                  View Report
                </Button>
              ),
            ]}
            actions={[
              isMobile && (
                <Link
                  key={"download"}
                  onClick={() =>
                    window.open(audiologyDetails?.reportUrl, "_blank")
                  }
                >
                  <EyeOutlined className="mr05" /> View Report
                </Link>
              ),
            ]}
          >
            <Row gutter={[32, 16]} justify="space-between">
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <img
                  src={audiologyDetails?.riskClusterUrl}
                  alt={"chart"}
                  width="100%"
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="bold mt05">Legend</div>
                <Divider style={{ margin: "0.25rem 0 1rem 0" }} />
                <Row gutter={[16, 8]} justify="space-between" className="mt1">
                  {content?.audiology?.risk?.legends?.map((item, index) =>
                    renderLegends(item, index)
                  )}
                </Row>
                <div className="bold mt2">Baseline Audiology Risk Score</div>
                <Divider style={{ margin: "0.25rem 0 1rem 0" }} />

                {audiologyDetails && (
                  <BarGraph audiologyDetails={audiologyDetails} />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    ) : (
      <Card>
        <Empty />
      </Card>
    ))
  );
};

export default Audiology;
