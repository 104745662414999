import { Button, Card, Col, Empty, Form, Row } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProfileDetails } from "../../../../Reducers/profileSlice";
import { putApis } from "../../../Common/Apis";
import { NOTIFICATIONS, USER_TYPES } from "../../../Common/Constants";
import { patientUpdateProfileUrl } from "../../../Common/Endpoints";
import useNotify from "../../../Common/Notify";
import {
  convertHeightToCm,
  convertLbsToKg,
  postMessageToMobileApp,
  logAnalyticsEvent,
  setUserDetails,
  isAndroidOrIosApp,
} from "../../../Common/Utils";
import Connect from "./Connect";
import ConnectRing from "./ConnectRing";
import List from "./List";

const ConnectedDevices = ({
  devices,
  deviceDetails,
  deviceImages,
  showLoader,
  orderDetails,
  userDetails,
  deviceConnectionDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [isRingModalOpen, setIsRingModalOpen] = useState(false);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { notify } = useNotify();
  const isMobileApp = isAndroidOrIosApp();

  const showRingModal = (type) => {
    if (type && type === "edit") {
      setIsRingModalOpen(true);
      setIsListModalOpen(false);
    } else {
      if (
        userDetails?.feet &&
        userDetails?.inches &&
        userDetails?.lbsWeight &&
        userDetails?.skinColor
      ) {
        const height = convertHeightToCm(
          userDetails?.feet,
          userDetails?.inches
        );
        const weight = convertLbsToKg(userDetails?.lbsWeight);
        postMessageToMobileApp(
          {
            height: height,
            weight: weight,
            skinColor: userDetails?.skinColor,
            age: userDetails?.age,
            gender: userDetails?.gender,
          },
          "connectToDevice"
        );
        setIsListModalOpen(false);
      } else {
        setIsRingModalOpen(true);
        setIsListModalOpen(false);
      }
    }
  };

  const handleRingModelCancel = () => {
    setIsRingModalOpen(false);
  };

  const showListModal = () => {
    setIsListModalOpen(true);
    setIsRingModalOpen(false);
  };

  const handleListCancel = () => {
    setIsListModalOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const height = convertHeightToCm(values?.feet, values?.inches);
    const weight = convertLbsToKg(values?.lbsWeight);
    postMessageToMobileApp({ ...values, height, weight }, "connectToDevice");
    await putApis(patientUpdateProfileUrl, values)
      .then((response) => {
        if (isAndroidOrIosApp()) {
          logAnalyticsEvent("mobile_connect_ring", {
            userType: USER_TYPES.PATIENT,
            data: userDetails?.email,
          });
        }
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        setLoading(false);
        setIsRingModalOpen(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const connectActions = () => {
    return (
      isMobileApp && (
        <Row
          key="actions"
          gutter={[16, 16]}
          justify="center"
          className={isMobileApp ? "mb2" : ""}
        >
          <Col xs={24} sm={12} md={12} lg={8} xl={6}>
            <Button key="connect" block onClick={() => showListModal()}>
              Connect New Device
            </Button>
          </Col>
        </Row>
      )
    );
  };

  return (
    <>
      {!showLoader && (
        <>
          {connectActions()}
          {deviceDetails ? (
            <>
              <List
                deviceDetails={deviceDetails}
                deviceImages={deviceImages}
                remoteConfigDevices={devices}
                deviceConnectionDetails={deviceConnectionDetails}
                showRingModal={showRingModal}
                isMobileApp={isMobileApp}
              />
            </>
          ) : (
            !orderDetails && (
              <Card>
                <Empty
                  description={
                    "Device orders will be offered exclusively to particular programs."
                  }
                />
              </Card>
            )
          )}
        </>
      )}
      {isListModalOpen && (
        <Connect
          userDetails={userDetails}
          deviceConnectionDetails={deviceConnectionDetails}
          isListModalOpen={isListModalOpen}
          handleListCancel={handleListCancel}
          devices={devices?.filter((item) => item?.value === "Saiwell_RingV2")}
          showRingModal={showRingModal}
        />
      )}
      {isRingModalOpen && (
        <ConnectRing
          form={form}
          loading={loading}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          userDetails={userDetails}
          isRingModalOpen={isRingModalOpen}
          handleRingModelCancel={handleRingModelCancel}
        />
      )}
    </>
  );
};

export default ConnectedDevices;
