import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Steps } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../Common/Constants";
import Confirm from "./Confirm";
import Location from "./Location";
import TimeSlots from "./TimeSlots";

const PatientFotona = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const step = searchParams.get("step");
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const [currentStep, setCurrentStep] = useState();
  const activeDate = moment().add(14, "days");

  useEffect(() => {
    setCurrentStep(step ? parseInt(step) : 0);
  }, [step]);

  return (
    <Row>
      <Col span={24}>
        <Button href={ROUTES.PATIENT.BOOKINGS.PATH} size="small">
          <ArrowLeftOutlined className="mr025" /> Back to Bookings
        </Button>
        <Card className="mt1">
          {isMobile ? (
            <Steps
              current={currentStep}
              direction="horizontal"
              labelPlacement="horizontal"
              style={{ flexDirection: "row", textAlign: "center" }}
              items={[
                {
                  title: "",
                },
                {
                  title: "",
                },
                {
                  title: "",
                },
              ]}
            />
          ) : (
            <Steps
              current={currentStep}
              direction="horizontal"
              labelPlacement="vertical"
              items={[
                {
                  title: "Location",
                },
                {
                  title: "Slots",
                },
                {
                  title: "Done",
                },
              ]}
            />
          )}
          <div data-testid="fotona">
            {currentStep === 0 && (
              <Location
                activeDate={activeDate}
                setCurrentStep={setCurrentStep}
              />
            )}
            {currentStep === 1 && (
              <TimeSlots
                activeDate={activeDate}
                setCurrentStep={setCurrentStep}
              />
            )}
            {currentStep === 2 && <Confirm />}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default PatientFotona;
