import { Button, Card, Col, Divider, Empty, Modal, Row, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingDetails,
  setTimeSlots,
} from "../../../../Reducers/bookingsSlice";
import { getApis, postApis } from "../../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../../Common/Constants";
import {
  patientFotonaAvailableSlotsUrl,
  patientFotonaBookingsUrl,
} from "../../../Common/Endpoints";
import MonthlyCalendar from "../../../Common/MonthlyCalendar";
import {
  centalisedSubmitBtn,
  disabledFotonaBookingDates,
} from "../../../Common/Utils";
import moment from "moment";
import useNotify from "../../../Common/Notify";

const TimeSlots = ({ setCurrentStep, activeDate }) => {
  const bookings = useSelector((state) => state?.bookings);
  const timeSlots = bookings?.timeSlots;
  const [amTimes, setAmTimes] = useState([]);
  const [pmTimes, setPmTimes] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const { confirm } = Modal;
  const [values, setValues] = useState({
    bookingDate: activeDate.format("YYYY-MM-DD"),
    slot: null,
  });
  const [errors, setErrors] = useState({
    bookingDate: false,
    slot: false,
  });
  const { notify } = useNotify();

  useEffect(() => {
    const am = timeSlots?.filter((time) => time.includes("AM"));
    const pm = timeSlots?.filter((time) => time.includes("PM"));

    setAmTimes(am);
    setPmTimes(pm);
  }, [timeSlots]);

  const validateFields = () => {
    const newErrors = {
      bookingDate: values.bookingDate === null,
      slot: values.slot === null,
    };
    setErrors(newErrors);
    return !newErrors.bookingDate && !newErrors.slot;
  };

  const submitHandler = async () => {
    setLoading(true);
    setShowNote(!bookings?.details?.singleSession);
    if (validateFields()) {
      await getApis(patientFotonaAvailableSlotsUrl, bookings?.details)
        .then((response) => {
          setShowNote(false);
          showSlotsConfirm(response?.data);
        })
        .catch((error) => {
          setLoading(false);
          setShowNote(false);
          notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
        });
    } else {
      setLoading(false);
    }
  };

  const bookASlot = async (availableDates) => {
    await postApis(patientFotonaBookingsUrl, {
      ...bookings?.details,
      bookingDate: availableDates,
    })
      .then(() => {
        dispatch(
          setBookingDetails({
            ...bookings?.details,
            bookingDate: availableDates,
          })
        );
        setLoading(false);
        setCurrentStep(2);
      })
      .catch(() => setLoading(false));
  };

  const showSlotsConfirm = (availableDates) => {
    confirm({
      title: "Are you sure?",
      content: (
        <>
          Your appointments:
          <ul className="list-paragraph">
            {availableDates?.map((item, index) => {
              return (
                <li key={index} className="grey-fill pd1 mt1">
                  {item?.dateString}
                </li>
              );
            })}
          </ul>
          <p>
            Please note that you can change your session time when you come in
            for your initial session.
          </p>
        </>
      ),
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        bookASlot(availableDates);
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const getAvailableSlots = async (params) => {
    await getApis(patientFotonaBookingsUrl, params)
      .then((response) => {
        setShowLoader(false);
        dispatch(setTimeSlots(response?.data));
        setValues({ ...values, slot: null });
      })
      .catch(() => {
        dispatch(setTimeSlots([]));
        setShowLoader(false);
      });
  };

  const handleDateSelect = async (date) => {
    const endDate = moment().add(14, "days").format("YYYY-MM-DD");
    const newSelectedDate = date.format("YYYY-MM-DD");

    const updatedValues = {
      ...bookings?.details,
      ...values,
      bookingDate: date.format("YYYY-MM-DD"),
    };
    setValues(updatedValues);

    if (moment(newSelectedDate).isAfter(moment(endDate))) {
      setShowLoader(true);
      getAvailableSlots(updatedValues);
    } else if (moment(newSelectedDate).isBefore(moment(endDate))) {
      dispatch(setTimeSlots([]));
      return;
    }

    dispatch(setBookingDetails(updatedValues));
  };

  const handleTagClick = (time) => {
    dispatch(setBookingDetails({ ...bookings?.details, slot: time }));
    setValues({ ...values, slot: time });
    setErrors({ ...errors, slot: false });
  };

  return (
    <div data-testid="timeslots">
      <div className="mt3">
        You will be automatically scheduled for three sessions that are spaced
        21 days apart. We ask that once your session dates are confirmed and
        scheduled that no changes be made to the date or time unless there is an
        emergency situation. You can reschedule your follow up sessions when you
        come in for your initial session if absolutely necessary.
      </div>
      <Row gutter={[16, 16]} className="mt3">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card
            className="box-shadow"
            styles={{
              body: {
                padding: "0.5rem 1rem",
              },
            }}
          >
            <MonthlyCalendar
              handleDateSelect={handleDateSelect}
              disabledDate={(current) => disabledFotonaBookingDates(current)}
            />
            {errors.bookingDate && (
              <p style={{ color: "red" }}>{VALIDATIONS.DEFAULT.BLANK}</p>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card
            className="box-shadow"
            title="Available Slots"
            styles={{
              body: {
                minHeight: "328px",
                height: "328px",
                overflow: "auto",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              },
            }}
          >
            <div className="flex-center">
              {showLoader ? (
                <Spin size="large" />
              ) : timeSlots?.length > 0 ? (
                <div>
                  <div className="bold blue">Morning</div>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  {amTimes?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        className={`mt1 ml05 cursor-pointer ${
                          values.slot === item ? "selected-tag" : ""
                        }`}
                        onClick={() => handleTagClick(item)}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                  <div className="bold blue mt2">Afternoon</div>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  {pmTimes?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        className={`mt1 ml05 cursor-pointer ${
                          values.slot === item ? "selected-tag" : ""
                        }`}
                        onClick={() => handleTagClick(item)}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </Card>
          {errors.slot && (
            <div style={{ color: "red" }} className="mt05">
              {VALIDATIONS.DEFAULT.BLANK}
            </div>
          )}
        </Col>
      </Row>

      {showNote && (
        <div className="mb1 mt2 bold text-center">
          It might take a little while to secure your slot when booking three
          sessions at once.
        </div>
      )}
      {centalisedSubmitBtn(loading, BUTTONS.NEXT, submitHandler, 4)}
      <div className="flex-center mt1">
        <Button size="small" onClick={() => setCurrentStep(0)}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default TimeSlots;
