import Popup from "../Popup";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useSelector } from "react-redux";
import { PDF_WORKER, USER_TYPES } from "../Constants";

const TermsOfUseModal = ({ isTermsModalOpen, handleTermsModalCancel }) => {
  const { documents } = useSelector((state) => state?.remoteConfig);
  const userType = useSelector((state) => state?.app?.userType);
  const pdfUrl = [USER_TYPES.PROVIDER, USER_TYPES.CLINIC_ADMIN].includes(
    userType
  )
    ? documents?.provider?.termsOfUse
    : documents?.patient?.termsOfUse;

  return (
    <Popup
      title="Terms of Use"
      open={isTermsModalOpen}
      cancel={handleTermsModalCancel}
      footer={null}
      width="100%"
      height="100%"
      bodyStyle={{ maxHeight: "85vh", overflow: "auto" }}
      style={{ top: "8px" }}
    >
      {pdfUrl && (
        <Worker workerUrl={PDF_WORKER.URL}>
          <Viewer fileUrl={pdfUrl} />
        </Worker>
      )}
    </Popup>
  );
};

export default TermsOfUseModal;
