import { Card, Col, Row } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BlueBanner from "../../Common/BlueBanner";
import {
  patientDashboardImages,
  patientSupportImages,
} from "../../Common/Images";
import { getPageHeader } from "../../Common/Utils";
import Technical from "./Technical";

const ProviderSupport = () => {
  const { support } = useSelector((state) => state?.remoteConfig);
  const [activeCard, setActiveCard] = useState("technical");

  const renderCard = (image, cardName) => {
    return (
      <Link onClick={() => setActiveCard(cardName)}>
        <Card
          className={`${
            activeCard === cardName ? "selected-card" : "hover-text"
          } box-shadow`}
          style={{ border: "0" }}
          styles={{ body: { padding: "1rem" } }}
        >
          <Row gutter={[16, 16]} className="flex-center">
            <Col span={6} style={{ lineHeight: 0 }}>
              <img src={image} alt={cardName} width="100%" />
            </Col>
            <Col span={14} className="bold fs20" style={{ lineHeight: 0 }}>
              {getPageHeader(cardName)}
            </Col>
            <Col span={3} style={{ lineHeight: 0 }}>
              <img
                src={
                  activeCard === cardName
                    ? patientSupportImages.SHOWMORE
                    : patientDashboardImages.ORANGE_VIEWMORE
                }
                alt="more"
                width="100%"
              />
            </Col>
          </Row>
        </Card>
      </Link>
    );
  };

  return (
    <div>
      <BlueBanner image={patientSupportImages.BANNER} span={6}>
        <div className="fs24 bold">We're Here to Help</div>
        <p>
          Explore solutions for technical support. Our dedicated team and
          resources ensure you have the help you need, when you need it.
        </p>
      </BlueBanner>

      <Row gutter={[16, 16]} className="mt2">
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          {renderCard(patientSupportImages.TECH, "technical")}
        </Col>
      </Row>
      {activeCard && (
        <Row gutter={[16, 16]} justify="center" className="mt2">
          <Col span={24}>
            {activeCard === "technical" && (
              <Technical
                setActiveCard={setActiveCard}
                activeCard={activeCard}
                staticContent={support?.technical}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProviderSupport;
