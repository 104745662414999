import { Viewer, Worker } from "@react-pdf-viewer/core";
import { Col, Flex, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import { PDF_WORKER } from "../Common/Constants";
const { Title } = Typography;

const PrivacyPolicy = () => {
  const { documents } = useSelector((state) => state?.remoteConfig);
  const pdfUrl = documents?.website?.privacyPolicy;

  return (
    <div data-testid="privacy-policy" className="pd2">
      <Row justify={"center"}>
        <Col span={24} className="text-center">
          <Title level={2}>
            <Flex wrap="wrap" className="flex-center">
              <span className="blue mr075">Privacy</span>
              <span className="orange">Policy</span>
            </Flex>
          </Title>
        </Col>
      </Row>
      {pdfUrl && (
        <div className="mt2">
          <Worker workerUrl={PDF_WORKER.URL}>
            <Viewer fileUrl={pdfUrl} />
          </Worker>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicy;
