import axios from "axios";
import { ROUTES } from "./Constants";
import { refreshTokenUrl } from "./Endpoints";
import { logout } from "./Utils";

const authorizedHeader = (authToken, contentType) => {
  if (authToken) {
    return {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": contentType ? contentType : "application/json",
    };
  } else {
    return { "Content-Type": "application/json" };
  }
};

export const getApis = async (url, params) => {
  try {
    return await axios({
      method: "get",
      url: url,
      params: params ? params : {},
      headers: authorizedHeader(localStorage.getItem("authToken")),
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newToken = await handleRequestError();
      if (newToken) {
        return getApis(url, params);
      } else {
        throw new Error("Failed to obtain new token");
      }
    } else if (error.response && error.response.status === 460) {
      window.location.assign(ROUTES.ACCESS_DENIED.PATH);
    } else {
      throw error;
    }
  }
};

export const postApis = async (url, body, contentType) => {
  try {
    return await axios({
      method: "post",
      url: url,
      data: body,
      json: true,
      headers: authorizedHeader(localStorage.getItem("authToken"), contentType),
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newToken = await handleRequestError();
      if (newToken) {
        return postApis(url, body);
      } else {
        throw new Error("Failed to obtain new token");
      }
    } else if (error.response && error.response.status === 460) {
      window.location.assign(ROUTES.ACCESS_DENIED.PATH);
    } else {
      throw error;
    }
  }
};

export const putApis = async (url, body) => {
  try {
    return await axios({
      method: "put",
      url: url,
      data: body,
      json: true,
      headers: authorizedHeader(localStorage.getItem("authToken")),
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newToken = await handleRequestError();
      if (newToken) {
        return putApis(url, body);
      } else {
        throw new Error("Failed to obtain new token");
      }
    } else if (error.response && error.response.status === 460) {
      window.location.assign(ROUTES.ACCESS_DENIED.PATH);
    } else {
      throw error;
    }
  }
};

export const deleteApis = async (url, params) => {
  try {
    return await axios({
      method: "delete",
      url: url,
      params: params ? params : {},
      headers: authorizedHeader(localStorage.getItem("authToken")),
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newToken = await handleRequestError();
      if (newToken) {
        return getApis(url, params);
      } else {
        throw new Error("Failed to obtain new token");
      }
    } else if (error.response && error.response.status === 460) {
      window.location.assign(ROUTES.ACCESS_DENIED.PATH);
    } else {
      throw error;
    }
  }
};

export const handleRequestError = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  localStorage.removeItem("authToken");
  try {
    return await axios({
      method: "get",
      url: refreshTokenUrl,
      params: {},
      headers: authorizedHeader(refreshToken),
    }).then((response) => {
      localStorage.setItem("authToken", response?.data?.access_token);
      localStorage.removeItem("userDetails");
      window.location.assign(ROUTES.LANDING.PATH);
      return response?.data?.access_token;
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      logout("token_expired");
      window.location.reload();
    }
    throw error;
  }
};
