import { createSlice } from "@reduxjs/toolkit";

const remoteConfigSlice = createSlice({
  name: "remoteConfig",
  initialState: {
    devices: null,
    orders: null,
    dropdowns: null,
    programs: null,
    releases: null,
    images: null,
    contents: null,
    bookings: null,
    questionnaires: null,
    insurances: null,
    payments: null,
    support: null,
    timelines: null,
    epicData: null,
    videos: null,
    faqs: null,
    documents: null,
    registration: null,
  },
  reducers: {
    setRemoteConfig: (state, action) => {
      state.questionnaires = action.payload?.QUESTIONNAIRES || null;
      state.devices = action.payload?.DEVICES || null;
      state.orders = action.payload?.ORDERS || null;
      state.dropdowns = action.payload?.DROPDOWNS || null;
      state.programs = action.payload?.PROGRAMS || null;
      state.releases = action.payload?.RELEASES || null;
      state.images = action.payload?.IMAGES || null;
      state.contents = action.payload?.CONTENTS || null;
      state.bookings = action.payload?.BOOKINGS || null;
      state.insurances = action.payload?.INSURANCES || null;
      state.payments = action.payload?.PAYMENTS || null;
      state.support = action.payload?.SUPPORT || null;
      state.timelines = action.payload?.TIMELINES || null;
      state.epicData = action.payload?.EPIC_DATA || null;
      state.videos = action.payload?.VIDEOS || null;
      state.faqs = action.payload?.FAQS || null;
      state.documents = action.payload?.DOCUMENTS || null;
      state.registration = action.payload?.REGISTRATION || null;
    },
  },
});

export const { setRemoteConfig } = remoteConfigSlice.actions;
export default remoteConfigSlice.reducer;
