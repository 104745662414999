import ONE from "../../../Assets/images/Dashboard/totalWellness.svg";
import TWO from "../../../Assets/images/Dashboard/physical.svg";
import THREE from "../../../Assets/images/Dashboard/cognitive.svg";
import UP from "../../../Assets/images/Dashboard/upArrow.svg";
import DOWN from "../../../Assets/images/Dashboard/downArrow.svg";
import { Col, Row } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { displayDateTime } from "../../Common/Utils";
import WellnessCard from "../../Common/WellnessCard";
import BlueBanner from "../../Common/BlueBanner";

const Wellness = ({ wellnessDetails }) => {
  const defaultDailyData = {
    1: {
      title: "Total",
      strokeColor: "#FF7D01",
      value: 0,
      delta: 0,
      imgSrc: ONE,
    },
    2: {
      title: "Physical",
      strokeColor: "#1CB4BF",
      value: 0,
      delta: 0,
      imgSrc: TWO,
    },
    3: {
      title: "Cognitive",
      strokeColor: "#FFCD00",
      value: 0,
      delta: 0,
      imgSrc: THREE,
    },
  };

  return (
    <div>
      {wellnessDetails?.lastUpdated && (
        <Row justify={"space-evenly"} className="mb2">
          <Col span={24}>
            <BlueBanner image={""} span={0}>
              <div>
                <span className="bold mr05">
                  <ClockCircleOutlined /> Last Updated At:
                </span>
                <span>{displayDateTime(wellnessDetails?.lastUpdated)}</span>
              </div>
            </BlueBanner>
          </Col>
        </Row>
      )}

      <Row gutter={[32, 24]} justify="space-between" className="mt1">
        {wellnessDetails?.list?.map((item, index) => {
          return (
            <WellnessCard
              key={index}
              apiData={item}
              barImage={defaultDailyData[item?.position]?.imgSrc}
              arrowImage={item?.delta > 0 ? UP : DOWN}
            />
          );
        })}
      </Row>
    </div>
  );
};

export default Wellness;
