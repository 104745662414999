import { Col, Flex, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import MAIN from "../../Assets/images/Tinnitus/main.png";
import EAR from "../../Assets/images/Tinnitus/ear.png";
import CAUSES from "../../Assets/images/Tinnitus/causes.png";
import HEARING from "../../Assets/images/Tinnitus/hearing.png";
import { ROUTES } from "../Common/Constants";
import ExperienceNow from "../Common/ExperienceNow";
import { TINNITUS } from "../Common/AppContent";
import ColorWidget from "../Common/ColorWidget";
import WwdCards from "./WwdCards";
import LOGO from "../../Assets/images/Tinnitus/logo.png";
import THERAPY from "../../Assets/images/Tinnitus/intervention.png";
import PATHWAY from "../../Assets/images/Tinnitus/pathway.png";
import VideoPlayer from "../Common/VideoPlayer";

const { Title } = Typography;

const Tinnitus = () => {
  const { videos } = useSelector((state) => state?.remoteConfig);
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;

  const defineTinnitusText = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={10} xl={10}>
        <Title className="fs28">
          <Flex wrap="wrap" className="flex-start-center">
            <span className="blue mr05">
              {TINNITUS.FEATURES.TWO.ORANGE_HEADER}
            </span>
            <span className="orange">{TINNITUS.FEATURES.TWO.BLUE_HEADER}</span>
          </Flex>
        </Title>
        <div className="paragraph mt2 fs16">
          <div
            dangerouslySetInnerHTML={{
              __html: TINNITUS.FEATURES.TWO.DESCRIPTION,
            }}
          />
        </div>
        <Row className="mt2">
          <Col xs={24} sm={12} md={8} lg={12} xl={12}>
            <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <div data-testid="tinnitus">
      {isMobile || isTablet ? (
        <Row className="blue-banner flex-center">
          <Col span={24}>
            <Row justify={"space-evenly"} gutter={[0, 32]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Title level={2} className="white flex-start-center">
                  {TINNITUS.TITLE}
                </Title>
                <div className="white fs20 mt2 ln-hgt105">
                  {TINNITUS.DESCRIPTION}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={10} xl={11}>
                <img src={MAIN} alt="Team" width="100%" />
              </Col>
            </Row>
            <Row className="mt2 mb1 flex-center">
              <Col xs={24} sm={12} md={10} lg={10} xl={10}>
                <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row
          justify={"space-evenly"}
          gutter={[0, 16]}
          className="blue-banner flex-center"
        >
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Title level={1} className="white flex-start-center">
              {TINNITUS.TITLE}
            </Title>
            <div className="white fs20 mt2 ln-hgt105">
              {TINNITUS.DESCRIPTION}
            </div>
            <Row className="mt3">
              <Col xs={24} sm={12} md={10} lg={10} xl={10}>
                <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={11}>
            <img src={MAIN} alt="Team" width="100%" />
          </Col>
        </Row>
      )}

      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="grey-fill flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <img src={EAR} alt="Ear" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="orange mr05">
                {TINNITUS.FEATURES.ONE.BLUE_HEADER}
              </span>
              <span className="blue">
                {TINNITUS.FEATURES.ONE.ORANGE_HEADER}
              </span>
            </Flex>
          </Title>
          <div className="paragraph mt2 fs16">
            {TINNITUS.FEATURES.ONE.DESCRIPTION}
          </div>
          <Row className="mt2">
            <Col xs={24} sm={12} md={8} lg={12} xl={12}>
              <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>

      {isMobile ? (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={CAUSES} alt="Causes" width="100%" />
          </Col>
          {defineTinnitusText()}
        </Row>
      ) : (
        <Row
          gutter={[0, 16]}
          justify={"space-evenly"}
          className="flex-align-center pd3"
        >
          {defineTinnitusText()}
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <img src={CAUSES} alt="Causes" width="100%" />
          </Col>
        </Row>
      )}

      <Row justify={"space-evenly"} className="grey-fill pd3">
        <Col span={24}>
          <div className="text-center">
            <Title level={2}>
              <span className="blue mr05">
                {TINNITUS.FEATURES.WHAT_WE_DO.BLUE_HEADER}
              </span>
              <span className="orange">
                {TINNITUS.FEATURES.WHAT_WE_DO.ORANGE_HEADER}
              </span>
            </Title>
          </div>
          <Row gutter={[16, 16]} justify={"space-evenly"} className="mt3">
            {TINNITUS.FEATURES.WHAT_WE_DO.CARDS?.map((item, index) => {
              return (
                <WwdCards
                  isMobile={isMobile}
                  isTablet={isTablet}
                  key={index}
                  index={index}
                  minHeight="350px"
                  imgSrc={item?.IMG_SRC}
                  title={item?.TITLE}
                  description={item?.DESCRIPTION}
                  borderColor={item?.BORDER}
                />
              );
            })}
          </Row>
        </Col>
      </Row>

      <Row
        gutter={[0, 16]}
        justify={"space-evenly"}
        className="flex-align-center pd3"
      >
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <img src={HEARING} alt="hearing" width="100%" />
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Title className="fs28">
            <Flex wrap="wrap" className="flex-start-center">
              <span className="orange mr05">
                {TINNITUS.FEATURES.THREE.BLUE_HEADER}
              </span>
              <span className="blue">
                {TINNITUS.FEATURES.THREE.ORANGE_HEADER}
              </span>
            </Flex>
          </Title>
          <div className="paragraph mt2 fs16">
            <div
              dangerouslySetInnerHTML={{
                __html: TINNITUS.FEATURES.THREE.DESCRIPTION,
              }}
            />
          </div>
          <Row className="mt2">
            <Col xs={24} sm={12} md={8} lg={12} xl={12}>
              <ExperienceNow redirectPath={ROUTES.PATIENT.REGISTER.PATH} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify={"space-evenly"} className="flex-center">
        <Col span={24}>
          <div className="grey-fill pd3">
            <div className="text-center">
              <Title level={isMobile ? 3 : 2}>
                <div className="orange">{TINNITUS.PATHWAY.ORANGE_HEADER}</div>
                <div className="mt05 blue flex-center">
                  {TINNITUS.PATHWAY.BLUE_HEADER_1}
                  <img alt="logo" src={LOGO} className="mr05 ml05" />
                  {TINNITUS.PATHWAY.BLUE_HEADER_2}
                </div>
              </Title>
            </div>
            <Col span={24} className="mt3">
              <img alt="pathway" src={PATHWAY} width="100%" />
            </Col>
          </div>
        </Col>
      </Row>

      <Row justify={"space-evenly"} className="flex-center">
        <Col span={24} className="pd3">
          <div className="text-center">
            <Title level={isMobile ? 3 : 2}>
              <div className="orange">{TINNITUS.THERAPY.ORANGE_HEADER}</div>
              <div className="mt05 blue flex-center">
                {TINNITUS.THERAPY.BLUE_HEADER_1}
                <img alt="logo" src={LOGO} className="mr05 ml05" />
                {TINNITUS.THERAPY.BLUE_HEADER_2}
              </div>
            </Title>
          </div>
          <Col span={24} className="mt3">
            <img alt="therapy" src={THERAPY} width="100%" />
          </Col>
        </Col>
      </Row>

      <Row justify={"space-evenly"} className="grey-fill pd3">
        <Col span={24}>
          <div className="text-center">
            <Title level={2}>
              <div className="blue">{TINNITUS.IMPACT.BLUE_HEADER}</div>
              <div className="orange">{TINNITUS.IMPACT.ORANGE_HEADER}</div>
            </Title>
          </div>
        </Col>

        <Col span={24} className="mt3">
          <VideoPlayer
            link={videos?.tinnitus?.link}
            thumbnailWithIcon={videos?.tinnitus?.withIcon}
            thumbnailWithoutIcon={videos?.tinnitus?.withoutIcon}
          />
        </Col>
      </Row>

      <Row justify={"space-evenly"} className="pd3">
        <Col span={24}>
          <div className="text-center">
            <Title level={2}>
              <div className="blue">{TINNITUS.BENEFITS.BLUE_HEADER}</div>
              <div className="orange">{TINNITUS.BENEFITS.ORANGE_HEADER}</div>
            </Title>
          </div>
          <Row gutter={[16, 16]} justify={"space-evenly"} className="mt3">
            {TINNITUS.BENEFITS.CARDS?.map((item, index) => {
              return (
                <ColorWidget
                  key={index}
                  minHeight={"372px"}
                  imgSrc={item?.IMG_SRC}
                  title={item?.TITLE}
                  description={item?.DESCRIPTION}
                  textClass={item?.TEXT_CLASS}
                  widgetClass={item?.WIDGET_CLASS}
                />
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Tinnitus;
