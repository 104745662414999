import { Button, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmrSelectedKey } from "../../../../Reducers/patientSlice";
import { postApis } from "../../../Common/Apis";
import {
  BUTTONS,
  FORM_FIELDS,
  PLACEHOLDER,
  VALIDATIONS,
} from "../../../Common/Constants";
import { providerViewPatientEmrDataUrl } from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import { renderKeyValueDetails } from "../../../Common/Utils";

const DiagnosisDetails = ({
  emrDetails,
  token,
  getEmrData,
  staticDropdownData,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(providerViewPatientEmrDataUrl, {
      token: token,
      category: "diagnosisDetails",
      formData: values,
    })
      .then(() => {
        setLoading(false);
        getEmrData();
        dispatch(setEmrSelectedKey(5));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return Object.keys(emrDetails?.diagnosisDetails)?.length > 0 ? (
    <Row>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.COMORBIDITIES,
          emrDetails?.diagnosisDetails?.comorbidities
        )}
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.ADMISSION_DIAGNOSIS,
          emrDetails?.diagnosisDetails?.presentOnAdmissionCodes
        )}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {renderKeyValueDetails(
          isMobile,
          FORM_FIELDS.PRIMARY_DIAGNOSIS,
          emrDetails?.diagnosisDetails?.primaryDiagnosisCodes
        )}
      </Col>
    </Row>
  ) : (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      size="large"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={{
        required: VALIDATIONS.DEFAULT.BLANK,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            label={FORM_FIELDS.COMORBIDITIES}
            name="comorbidities"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              name="comorbidities"
              mode="multiple"
              maxTagCount="responsive"
              placeholder={PLACEHOLDER.PLEASE_SELECT}
              options={staticDropdownData?.comorbidity}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.PRIMARY_DIAGNOSIS}
            name="primaryDiagnosisCodes"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern:
                  /^([A-Z][0-9][A-Z0-9](?:\.[A-Z0-9]{1,4})?)(,*[A-Z][0-9][A-Z0-9](?:\.[A-Z0-9]{1,4})?)*$/,
                message: VALIDATIONS.PATTERN.ICD_CODE,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.PRIMARY_DIAGNOSIS} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={FORM_FIELDS.ADMISSION_DIAGNOSIS}
            name="presentOnAdmissionCodes"
            size="large"
            rules={[
              {
                required: true,
              },
              {
                pattern:
                  /^([A-Z][0-9][A-Z0-9](?:\.[A-Z0-9]{1,4})?)(,*[A-Z][0-9][A-Z0-9](?:\.[A-Z0-9]{1,4})?)*$/,
                message: VALIDATIONS.PATTERN.ICD_CODE,
              },
            ]}
          >
            <Input placeholder={PLACEHOLDER.ADMISSION_DIAGNOSIS} />
          </Form.Item>
        </Col>
      </Row>

      <Row className="flex-center">
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Form.Item className="mt1">
            <Button type="primary" htmlType="submit" block disabled={loading}>
              <Loader loading={loading} text={BUTTONS.SUBMIT} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DiagnosisDetails;
