import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis } from "../../Common/Apis";
import {
  patientDeviceDetailsUrl,
  patientDeviceOrderUrl,
} from "../../Common/Endpoints";
import ConnectedDevices from "./Connected";
import Orders from "./Orders";

const Devices = () => {
  const userDetails =
    useSelector((state) => state?.profile?.profileDetails) ||
    JSON.parse(localStorage.getItem("userDetails"));
  const [deviceDetails, setDeviceDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [orderDetails, setOrderDetails] = useState();
  const deviceConnectionDetails = JSON.parse(
    localStorage.getItem("deviceConnectionDetails")
  );
  const { devices, orders } = useSelector((state) => state?.remoteConfig);
  const devicesList = devices?.devices;
  const deviceImages = devices?.images;
  const questionnaires = orders;

  const getDeviceDetails = async () => {
    setShowLoader(true);
    await getApis(patientDeviceDetailsUrl)
      .then((response) => {
        setShowLoader(false);
        setDeviceDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const getDeviceOrders = async () => {
    setShowLoader(true);
    await getApis(patientDeviceOrderUrl)
      .then((response) => {
        setShowLoader(false);
        setOrderDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    getDeviceDetails();
    getDeviceOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ConnectedDevices
        deviceDetails={deviceDetails}
        showLoader={showLoader}
        deviceImages={deviceImages}
        devices={devicesList}
        orderDetails={orderDetails}
        userDetails={userDetails}
        deviceConnectionDetails={deviceConnectionDetails}
      />
      <Orders
        orderDetails={orderDetails}
        questionnaires={questionnaires}
        deviceDetails={deviceDetails}
        showLoader={showLoader}
        devices={devicesList}
        deviceImages={deviceImages}
        userDetails={userDetails}
      />
    </div>
  );
};

export default Devices;
