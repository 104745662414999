import { Button, Card, Col, Form, Input, Modal, Row, Select, Tabs } from "antd";
import { useEffect, useState } from "react";
import { getApis, postApis, putApis } from "../../Common/Apis";
import {
  BUTTONS,
  NOTIFICATIONS,
  ROUTES,
  SERVICES,
  USER_TYPES,
} from "../../Common/Constants";
import { useNavigate } from "react-router-dom";
import History from "../../Common/Bookings/History";
import useNotify from "../Notify";
import View from "./View";
import Reschedule from "./Reschedule";
import { useDispatch, useSelector } from "react-redux";
import { useApiLoader } from "../ApiLoaderContext";
import { centalisedSubmitBtn, setUserDetails } from "../../Common/Utils";
import {
  bookingsLocationsUrl,
  clinicAdminSleepQuestionnairesUrl,
  patientBookingsSleepCheckUrl,
  patientQuestionnairesUrl,
  patientUpdateProfileUrl,
} from "../Endpoints";
import ViewPatient from "./ViewPatient";
import Active from "./Active";
import {
  setReOrderDetails,
  setTimeSlots,
} from "../../../Reducers/bookingsSlice";
import Pending from "./Pending";
import { EditOutlined } from "@ant-design/icons";
import SleepQuestionnaireForm from "../../Patients/Bookings/SleepQuestionnaireForm";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import ViewQuestionnaires from "./Questionnaires";
const { Search } = Input;

const Bookings = ({ getUrl, redirectUrl, putUrl, locationType }) => {
  const userType =
    useSelector((state) => state?.app?.userType) ||
    localStorage.getItem("userType");
  const [activeTab, setActiveTab] = useState(
    userType === USER_TYPES.CLINIC_ADMIN ? "upcoming" : "active"
  );
  const { bookings, dropdowns, contents, questionnaires } = useSelector(
    (state) => state?.remoteConfig
  );
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [bookingList, setBookingList] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewPatientModalOpen, setPatientViewModalOpen] = useState(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [questionnaireModalOpen, setQuestionnaireModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [locations, setLocations] = useState();
  const [location, setLocation] = useState();
  const { setShowFullLoader } = useApiLoader();
  const [searchQuery, setSearchQuery] = useState();
  const [sleepQuestionnaireDetails, setSleepQuestionnaireDetails] = useState();
  const [sleepModalOpen, setSleepModalOpen] = useState(false);
  const [questionnaireMappedData, setQuestionnaireMappedData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { notify } = useNotify();
  const dispatch = useDispatch();

  const getBookings = async () => {
    setShowLoader(true);
    const params =
      userType === USER_TYPES.CLINIC_ADMIN && location
        ? { type: activeTab, location: location }
        : { type: activeTab };
    await getApis(getUrl, params)
      .then((response) => {
        setBookingList(response?.data);
        setShowLoader(false);
      })
      .catch(() => {
        setBookingList();
        setShowLoader(false);
      });
  };

  useEffect(() => {
    if (!locations && userType === USER_TYPES.CLINIC_ADMIN) {
      getLocations();
    }
    if (location || userType !== USER_TYPES.CLINIC_ADMIN) {
      getBookings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location]);

  const getLocations = async () => {
    await getApis(bookingsLocationsUrl, { type: locationType })
      .then((response) => {
        setLocations(response?.data);
        setLocation(response?.data?.[0]?.value);
      })
      .catch(() => {});
  };

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const cancelViewModal = () => {
    setViewModalOpen(false);
  };

  const cancelPatientViewModal = () => {
    setPatientViewModalOpen(false);
  };

  const showRescheduleModal = (record) => {
    form.resetFields();
    setSelectedRow(record);
    setRescheduleModalOpen(true);
  };

  const cancelRescheduleModal = () => {
    setRescheduleModalOpen(false);
    setSelectedRow(null);
    dispatch(setTimeSlots([]));
  };

  const getBookingDetails = async (record) => {
    setViewModalOpen(true);
    setSelectedRow(record);
  };

  const getPatientDetails = async (record) => {
    setPatientViewModalOpen(true);
    setSelectedRow(record);
  };

  const viewQuestionnaires = async (record) => {
    await getApis(clinicAdminSleepQuestionnairesUrl, {
      identifier: record?.identifier,
      bookingId: record?.bookingID,
    })
      .then((response) => {
        setShowLoader(false);
        setQuestionnaireModalOpen(true);
        setQuestionnaireMappedData(getQuestions(response?.data));
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const cancelQuestionnairesModal = () => {
    setQuestionnaireModalOpen(false);
  };

  const getQuestions = (data) => {
    if (!data) return [];

    return Object.keys(data)
      .filter((questionKey) => questionKey.startsWith("Q"))
      .map((questionKey) => {
        const answerValue = data[questionKey];
        const list =
          questionnaires?.[userDetails?.clinic]?.["Sleep"]?.[data?.subType]
            ?.questionnaires;
        const question = list
          ?.find((dataItem) => dataItem?.value === questionKey?.charAt(1))
          ?.questions?.find((q) => q?.value === questionKey);

        const selectedOption = list
          ?.find((dataItem) => dataItem?.value === questionKey?.charAt(1))
          ?.options?.find((option) => option?.value === answerValue);

        return {
          value: questionKey,
          title: question?.title,
          selectedOption:
            questionKey === "QA1" && data?.["QA1"] === "3"
              ? data?.["QA1Reason"]
              : selectedOption?.label,
        };
      });
  };

  const showCancelConfirm = (item) => {
    confirm({
      title: "Are you sure?",
      content:
        userType === USER_TYPES.CLINIC_ADMIN
          ? contents?.clinicAdmin?.bookings?.cancel
          : contents?.patient?.bookings?.cancel,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        cancelBookings(item);
      },
      onCancel() {},
    });
  };

  const handleLocationChange = (value) => {
    setLocation(value);
  };

  const cancelBookings = async (record) => {
    setShowFullLoader(true);
    await postApis(putUrl, {
      bookingID: record?.bookingID,
      clinicName: record?.clinic,
      action: "cancelled",
      bookedBy: record?.bookedBy,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setShowFullLoader(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const handleSearch = async (query) => {
    setShowLoader(true);
    const params = query
      ? {
          type: activeTab,
          location: location,
          searchBy: query,
        }
      : {
          type: activeTab,
          location: location,
        };
    await getApis(getUrl, params)
      .then((response) => {
        setBookingList(response?.data);
        setShowLoader(false);
      })
      .catch(() => {
        setBookingList();
        setShowLoader(false);
      });
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const reOrderBookings = async (record) => {
    dispatch(setReOrderDetails(record));
    navigate(ROUTES.CLINIC_ADMIN.SLEEP_MANUAL_BOOK.PATH);
  };

  const completeStudy = async (record) => {
    setShowFullLoader(true);
    await postApis(getUrl, {
      bookingID: record?.bookingID,
      sleepStudyCompleted: true,
      action: "updateData",
      bookedBy: record?.bookedBy,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setShowFullLoader(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const updateInsuranceStatus = async (record, status) => {
    setShowFullLoader(true);
    await postApis(getUrl, {
      bookingID: record?.bookingID,
      insuranceStatus: status,
      action: "updateData",
      bookedBy: record?.bookedBy,
    })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setShowFullLoader(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const renderComponent = (compnent) => {
    return (
      <div>
        {userType === USER_TYPES.CLINIC_ADMIN && (
          <Row justify={"space-between"} gutter={[16, 8]}>
            <Col
              xs={24}
              sm={6}
              md={2}
              lg={2}
              xl={2}
              className="bold flex-start-center"
            >
              Location:
            </Col>
            <Col xs={24} sm={18} md={10} lg={8} xl={8}>
              <Select
                name="locationFilter"
                title="Filter By Location"
                size="large"
                value={location}
                placeholder="Select Location"
                options={locations}
                className="full-width"
                onChange={handleLocationChange}
              />
            </Col>
            <Col xs={24} sm={24} md={10} lg={12} xl={12}>
              <Search
                allowClear
                value={searchQuery}
                placeholder="Search by First Name"
                onSearch={handleSearch}
                onChange={handleSearchInputChange}
                size="large"
                className="full-width search"
              />
            </Col>
          </Row>
        )}
        <div className="mt1">{compnent}</div>
      </div>
    );
  };

  const renderHistoryList = () => {
    return renderComponent(
      <History
        bookingList={bookingList}
        userType={userType}
        getBookingDetails={getBookingDetails}
        getPatientDetails={getPatientDetails}
        locationType={locationType}
        completeStudy={completeStudy}
        activeTab={activeTab}
        updateInsuranceStatus={updateInsuranceStatus}
        reOrderBookings={reOrderBookings}
        staticDropdownData={dropdowns}
        viewQuestionnaires={viewQuestionnaires}
      />
    );
  };

  const tabItems = [
    {
      key: userType === USER_TYPES.CLINIC_ADMIN ? "upcoming" : "active",
      label: "Active",
      children: renderComponent(
        <Active
          bookingList={bookingList}
          userType={userType}
          showRescheduleModal={showRescheduleModal}
          getBookingDetails={getBookingDetails}
          getPatientDetails={getPatientDetails}
          viewQuestionnaires={viewQuestionnaires}
          showCancelConfirm={showCancelConfirm}
          locationType={locationType}
        />
      ),
    },
    {
      key: "pending",
      label: "Pending",
      children: renderComponent(
        <Pending
          bookingList={bookingList}
          userType={userType}
          getBookingDetails={getBookingDetails}
          getPatientDetails={getPatientDetails}
          locationType={locationType}
        />
      ),
    },
    {
      key: "history",
      label: "History",
      children: renderHistoryList(),
    },
    {
      key: "completed",
      label: "Completed",
      children: renderHistoryList(),
    },
    {
      key: "cancelled",
      label: "Cancelled",
      children: renderHistoryList(),
    },
    {
      key: "noshow",
      label: "No Show",
      children: renderHistoryList(),
    },
  ];

  const onClickHandler = () => {
    navigate(redirectUrl);
  };

  const bookNowHandler = async (item) => {
    setShowLoader(true);
    if (userType === USER_TYPES.PATIENT) {
      await getApis(patientBookingsSleepCheckUrl)
        .then((response) => {
          setShowLoader(false);
          if (response?.data?.[0]?.submitted) {
            navigate(`${redirectUrl}?clinic=${item?.clinic?.value}`);
          } else {
            setSleepQuestionnaireDetails(response?.data);
            setSleepModalOpen(true);
          }
        })
        .catch(() => {
          setShowLoader(false);
        });
    } else {
      navigate(`${redirectUrl}?clinic=${item?.clinic?.value}`);
    }
  };

  const handleSleepModalCancel = () => {
    setSleepModalOpen(false);
  };

  const onSleepFinish = async (values) => {
    setLoading(true);
    const {
      clinic,
      service,
      type,
      subType,
      feet,
      inches,
      lbsWeight,
      sfotoSleepQuestionnaire,
      ...answers
    } = values;

    const data = {
      ...{ clinic, service, type, subType },
      answers,
    };

    upateHeightWeight({ feet, inches, lbsWeight, sfotoSleepQuestionnaire });
    await postApis(patientQuestionnairesUrl, data)
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.assign(
            `${ROUTES.LANDING.PATH}?clinicFromBookings=${clinic}`
          );
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = async () => {
    setLoading(false);
  };

  const upateHeightWeight = async (data) => {
    await putApis(patientUpdateProfileUrl, data)
      .then((response) => {
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
      })
      .catch(() => {});
  };

  return (
    !showLoader && (
      <div data-testid="bookings">
        {userType !== USER_TYPES.CLINIC_ADMIN ? (
          <>
            {bookings?.map((item, index) => {
              return (
                <Row key={index} gutter={[16, 16]} justify="space-around">
                  {item?.services?.map((service, subIndex) => {
                    return (
                      <Col
                        key={subIndex}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                      >
                        <Card className="orange-plane-card">
                          <Row gutter={[16, 16]} justify="space-between">
                            <Col span={8}>
                              <img
                                src={service?.image}
                                alt="Sleep"
                                width="100%"
                              />
                            </Col>

                            <Col span={15} className="vertical-align">
                              <div className="top">
                                <div className="bold fs22 blue">
                                  {service?.label}
                                </div>
                                <div className="bold green mt05">
                                  {item?.clinic?.label}
                                </div>
                              </div>
                              {!service?.label?.includes("Fotona") &&
                              redirectUrl ? (
                                <Button
                                  name="sleepBook"
                                  type="primary"
                                  className="bottom"
                                  onClick={() => bookNowHandler(item)}
                                >
                                  Book Now
                                </Button>
                              ) : (
                                <Button
                                  name="fotonaBook"
                                  type="primary"
                                  className="bottom"
                                  onClick={() =>
                                    navigate(ROUTES.PATIENT.FOTONA.PATH)
                                  }
                                >
                                  Book Now
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </>
        ) : (
          redirectUrl &&
          centalisedSubmitBtn(null, BUTTONS.BOOK_NEW, onClickHandler, 8)
        )}

        {userType === USER_TYPES.PATIENT && (
          <Row justify={"center"}>
            <Col xs={24} sm={24} md={24} lg={20} xl={24}>
              <Card className="mt1 box-shadow">
                <Row gutter={[16, 16]}>
                  <Col
                    xs={24}
                    sm={20}
                    md={20}
                    lg={20}
                    xl={20}
                    className="flex-start-center"
                  >
                    {userDetails?.insurance ? (
                      <div className="mr025">
                        Your currently selected insurance is
                      </div>
                    ) : (
                      <div className="mr025">
                        You have not opted any insurance yet
                      </div>
                    )}
                    <div className="bold">{userDetails?.insurance}</div>
                  </Col>
                  <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                    <Button
                      block
                      icon={<EditOutlined />}
                      onClick={() =>
                        navigate(`${ROUTES.PATIENT.PROFILE.PATH}#Insurance`)
                      }
                    >
                      {userDetails?.insurance ? "Edit Here" : "Add Here"}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}

        <Card className="mt1">
          <Tabs
            type="card"
            defaultActiveKey={activeTab}
            onChange={onTabChange}
            items={
              userType === USER_TYPES.CLINIC_ADMIN
                ? tabItems?.filter((item) => !["history"].includes(item?.key))
                : tabItems?.filter(
                    (item) =>
                      !["pending", "completed", "cancelled", "noshow"].includes(
                        item?.key
                      )
                  )
            }
          />
        </Card>
        {viewModalOpen && (
          <View
            modalOpen={viewModalOpen}
            cancelModal={cancelViewModal}
            details={selectedRow}
            showQR={selectedRow?.bookingType !== "SFOTO-FOTONA-NIGHTLASE"}
          />
        )}
        {viewPatientModalOpen && (
          <ViewPatient
            modalOpen={viewPatientModalOpen}
            cancelModal={cancelPatientViewModal}
            details={selectedRow}
          />
        )}
        {rescheduleModalOpen && (
          <Reschedule
            form={form}
            modalOpen={rescheduleModalOpen}
            cancelModal={cancelRescheduleModal}
            bookingsUrl={putUrl}
            selectedRow={selectedRow}
            userType={userType}
          />
        )}
        {sleepModalOpen && questionnaires && (
          <SleepQuestionnaireForm
            loading={loading}
            isModalOpen={sleepModalOpen}
            handleCancel={handleSleepModalCancel}
            userDetails={userDetails}
            onFinish={onSleepFinish}
            onFinishFailed={onFinishFailed}
            sleepQuestionnaireDetails={sleepQuestionnaireDetails}
            questionnaireRawDetails={
              questionnaires?.["SFOTO"]?.[SERVICES.SLEEP]
            }
          />
        )}

        {questionnaireModalOpen && questionnaireMappedData && (
          <ViewQuestionnaires
            questionnaireModalOpen={questionnaireModalOpen}
            cancelQuestionnairesModal={cancelQuestionnairesModal}
            questionnaireMappedData={questionnaireMappedData}
          />
        )}
      </div>
    )
  );
};

export default Bookings;
