import { Button, Card, Col, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { getApis } from "../../Common/Apis";
import BlueBanner from "../../Common/BlueBanner";
import { ROUTES } from "../../Common/Constants";
import {
  patientHealthDetailsUrl,
  patientHealthSourcesUrl,
} from "../../Common/Endpoints";
import { patientHealthImages } from "../../Common/Images";
import ActiveEnergy from "./ActiveEnergy";
import BasalEnergy from "./BasalEnergy";
import BloodOxygen from "./BloodOxygen";
import Distance from "./Distance";
import FlightsClimbed from "./FlightsClimbed";
import HeartRate from "./HeartRate";
import RespiratoryRate from "./RespiratoryRate";
import Sleep from "./Sleep";
import Steps from "./Steps";
import Workout from "./Workout";

const Health = () => {
  const app = useSelector((state) => state?.app);
  const isMobile = app?.isMobile;
  const isTablet = app?.isTablet;
  const { showFullLoader, setShowFullLoader } = useApiLoader();
  const [healthDetails, setHealthDetails] = useState([]);
  const [sourceDetails, setSourceDetails] = useState();
  const [cardLoadingState, setCardLoadingState] = useState({});
  const [source, setSource] = useState();
  const navigate = useNavigate();
  const userDetails =
    useSelector((state) => state?.profile?.profileDetails) ||
    JSON.parse(localStorage.getItem("userDetails"));
  const connectToRing = userDetails?.programs?.includes("SAIW-RING");

  const cardComponents = {
    ACTIVE_ENERGY_BURNED: ActiveEnergy,
    FLIGHTS_CLIMBED: FlightsClimbed,
    DISTANCE_WALKING_RUNNING: Distance,
    STEPS: Steps,
    BASAL_ENERGY_BURNED: BasalEnergy,
    HEART_RATE: HeartRate,
    RESPIRATORY_RATE: RespiratoryRate,
    BLOOD_OXYGEN: BloodOxygen,
    WORKOUT: Workout,
    SLEEP_IN_BED: Sleep,
  };

  const getHealthDetails = async (source, frequency, card) => {
    try {
      const response = await getApis(patientHealthDetailsUrl, {
        sourceId: source,
        frequency: frequency,
        cardType: card,
      });
      return response?.data;
    } catch (error) {
      return null;
    }
  };

  const getSources = async () => {
    setShowFullLoader(true);

    try {
      const response = await getApis(patientHealthSourcesUrl);
      setShowFullLoader(false);

      const sourcesValue = response?.data?.sources?.[0]?.value;
      const cards = response?.data?.cards;

      setSourceDetails(response?.data);
      setSource(sourcesValue);

      if (sourcesValue && Array.isArray(cards)) {
        const promises = cards.map((card) =>
          getHealthDetails(sourcesValue, "day", card).then((data) => ({
            cardValue: card,
            cardFrequency: "day",
            data,
          }))
        );

        const results = await Promise.all(promises);

        setHealthDetails(results);
      }
    } catch (error) {
      setShowFullLoader(false);
    }
  };

  useEffect(() => {
    getSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSourceChange = async (value) => {
    setShowFullLoader(true);

    try {
      const promises = sourceDetails?.cards.map((card) =>
        getHealthDetails(value, "day", card).then((data) => ({
          cardValue: card,
          cardFrequency: "day",
          data,
        }))
      );
      setSource(value);
      const results = await Promise.all(promises);
      setHealthDetails(results);
      setShowFullLoader(false);
    } catch (error) {
      setShowFullLoader(false);
    }
  };

  const onFrequencyChange = async (value, cardType) => {
    setCardLoadingState((prevState) => ({
      ...prevState,
      [cardType]: true,
    }));
    try {
      const data = await getHealthDetails(source, value, cardType);

      setHealthDetails((prevHealthDetails) =>
        prevHealthDetails.map((item) =>
          item.cardValue === cardType
            ? { ...item, cardFrequency: value, data }
            : item
        )
      );
    } catch (error) {
      setCardLoadingState((prevState) => ({
        ...prevState,
        [cardType]: false,
      }));
    } finally {
      setCardLoadingState((prevState) => ({
        ...prevState,
        [cardType]: false,
      }));
    }
  };

  const renderComponent = (index, item, Component) => {
    const isLoading = cardLoadingState[item.cardValue];

    return (
      <Col key={index} xs={24} sm={24} md={24} lg={18} xl={12}>
        <Card style={{ minHeight: "354px" }}>
          {isLoading ? (
            <Spin className="flex-center pd3 mt4" size="large" />
          ) : (
            <Component
              isMobile={isMobile}
              isTablet={isTablet}
              healthDetails={item?.data}
              frequency={item?.cardFrequency}
              onFrequencyChange={onFrequencyChange}
            />
          )}
        </Card>
      </Col>
    );
  };

  const renderCard = (item, index) => {
    const Component = cardComponents[item.cardValue];
    if (Component && item?.data) {
      return renderComponent(index, item, Component);
    }
  };

  return (
    !showFullLoader && (
      <div>
        <BlueBanner image={patientHealthImages.BANNER} span={4}>
          <div className="fs24 bold">Monitor</div>
          <p>
            This section on the healthcare dashboard provides users with a
            comprehensive overview of their health metrics. It allows for easy
            access to historical and real-time data.
          </p>
          {connectToRing && (
            <Row>
              <Col xs={24} sm={10} md={8} lg={8} xl={6}>
                <Button
                  block
                  type="primary"
                  onClick={() => navigate(ROUTES.PATIENT.RING.PATH)}
                >
                  SAiWELL Ring
                </Button>
              </Col>
            </Row>
          )}
        </BlueBanner>

        <Row gutter={[16, 8]} className="mt1 flex-start-center">
          <Col xs={24} sm={8} md={6} lg={6} xl={4} className="bold">
            Select Your Device:
          </Col>

          <Col xs={24} sm={16} md={18} lg={12} xl={8}>
            <Select
              size="large"
              className="full-width"
              placeholder="Please Select"
              options={sourceDetails?.sources}
              value={source}
              onChange={onSourceChange}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt1">
          {healthDetails?.map((item, index) => renderCard(item, index))}
        </Row>
      </div>
    )
  );
};

export default Health;
