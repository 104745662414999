import { Button, Card, Col, Empty, Flex, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useApiLoader } from "../../../Common/ApiLoaderContext";
import { deleteApis, getApis, putApis } from "../../../Common/Apis";
import {
  clinicAdminSubscriptionsCardsUrl,
  clinicAdminSubscriptionsUrl,
} from "../../../Common/Endpoints";
import VISA from "../../../../Assets/images/visa.svg";
import MASTER from "../../../../Assets/images/master.svg";
import { getBaseUrl, getPageHeader } from "../../../Common/Utils";
import { DeleteOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import Loader from "../../../Common/Loader";
import { NOTIFICATIONS, ROUTES } from "../../../Common/Constants";
import useNotify from "../../../Common/Notify";
import { Link } from "react-router-dom";

const AccountSubscriptions = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const { showLoader, setShowLoader } = useApiLoader();
  const [loading, setLoading] = useState(false);
  const { notify } = useNotify();
  const { confirm } = Modal;

  const getSubscriptionDetails = async () => {
    setShowLoader(true);
    await getApis(clinicAdminSubscriptionsUrl)
      .then((response) => {
        setShowLoader(false);
        setSubscriptionDetails(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  const addNewCardHandler = async () => {
    setLoading(true);
    await getApis(clinicAdminSubscriptionsCardsUrl, {
      cancelUrl: `${getBaseUrl()}${
        ROUTES.CLINIC_ADMIN.ACCOUNT_SUBSCRIPTIONS.PATH
      }`,
      successUrl: `${getBaseUrl()}${
        ROUTES.CLINIC_ADMIN.ACCOUNT_SUBSCRIPTIONS.PATH
      }?`,
    })
      .then((response) => {
        setLoading(false);
        window.open(response?.data?.url, "_blank");
      })
      .catch(() => setLoading(false));
  };

  const showDeleteCardConfirm = (id) => {
    confirm({
      title: "Are you sure?",
      content: "This action will delete a card",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        deleteCardHandler(id);
      },
      onCancel() {},
    });
  };

  const deleteCardHandler = async (id) => {
    setShowLoader(true);
    await deleteApis(clinicAdminSubscriptionsCardsUrl, { id: id })
      .then((response) => {
        setShowLoader(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setShowLoader(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const updateCardHandler = async (id) => {
    setShowLoader(true);
    await putApis(clinicAdminSubscriptionsCardsUrl, { id: id })
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setShowLoader(false);
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getSubscriptionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !showLoader && (
      <>
        <Card
          title="Cards"
          extra={[
            <Button
              key={"add"}
              type="primary"
              onClick={addNewCardHandler}
              disabled={loading}
            >
              <Loader loading={loading} text={"Add New"} />
            </Button>,
          ]}
        >
          {!subscriptionDetails?.cards && (
            <Empty
              description="To ensure uninterrupted service, please add your card details here."
              imageStyle={{
                height: 70,
              }}
            />
          )}
          <Row gutter={[16, 16]}>
            {subscriptionDetails?.cards?.map((item, index) => {
              return (
                <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8}>
                  <div className="card">
                    <Row gutter={[16, 16]}>
                      <Col span={4}>
                        {item?.primary ? (
                          <div className="text-center">
                            <StarFilled
                              title={"Primary Card"}
                              style={{ fontSize: "18px", color: "#FF7D01" }}
                            />
                            <div className="fs11 bold">Primary</div>
                          </div>
                        ) : (
                          <Link
                            onClick={() => updateCardHandler(item?.id)}
                            className="flex-center mt05"
                          >
                            <StarOutlined
                              style={{ fontSize: "20px" }}
                              title={"Make this as Primary"}
                            />
                          </Link>
                        )}
                      </Col>
                      <Col span={6} className="flex-center">
                        <img
                          src={item?.brand === "mastercard" ? MASTER : VISA}
                          width="100%"
                          alt={item?.brand}
                        />
                      </Col>
                      <Col span={12} className="flex-start-center">
                        {getPageHeader(item?.brand)} ....{item?.last4}
                      </Col>
                      <Col span={2} className="flex-center">
                        {!item?.primary && (
                          <DeleteOutlined
                            className="icon18 cursor-pointer"
                            title="Delete"
                            onClick={() => showDeleteCardConfirm(item?.id)}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Card>
        <Card title="Payment Details" className="mt1">
          {typeof subscriptionDetails?.details === "string" ? (
            <div
              dangerouslySetInnerHTML={{ __html: subscriptionDetails?.details }}
            />
          ) : (
            <>
              <Flex className="mt1">
                <div className="mr05 grey">Amount:</div>
                <div>${subscriptionDetails?.details?.amount}</div>
              </Flex>
              <Flex className="mt05">
                <div className="mr05 grey">Last Debit Date:</div>
                <div>{subscriptionDetails?.details?.lastDebitDate}</div>
              </Flex>
              <Flex className="mt05">
                <div className="mr05 grey">Next Debit Date:</div>
                <div>{subscriptionDetails?.details?.nextDebitDate}</div>
              </Flex>
            </>
          )}
        </Card>
      </>
    )
  );
};

export default AccountSubscriptions;
