import { Card, Col, QRCode, Row } from "antd";
import Popup from "../../../Common/Popup";

const ProgramsQR = ({
  userDetails,
  programs,
  isQRListModalOpen,
  handleQRListModalCancel,
}) => {
  return (
    <Popup
      title={"Kits"}
      open={isQRListModalOpen}
      cancel={handleQRListModalCancel}
      footer={null}
      width={550}
      style={{ top: "8%" }}
    >
      <div>
        Scan the QR code below accordingly in the your IPAD app to add a new
        kit.
      </div>
      {programs?.map((item, index) => (
        <Card key={index} className="mt1 grey-fill">
          <Row gutter={[16, 16]} className="flex-start-center">
            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
              <QRCode
                value={`${userDetails?.clinic}_${item?.value}` || "NA"}
                bgColor="#fff"
                className="box-shadow"
                style={{ width: "100%", height: "auto" }}
              />
            </Col>

            <Col xs={24} sm={18} md={18} lg={18} xl={18}>
              <div className="bold blue fs16 ml1">{item?.label}</div>
            </Col>
          </Row>
        </Card>
      ))}
    </Popup>
  );
};

export default ProgramsQR;
