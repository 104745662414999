import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Divider, Form, Popover } from "antd";
import { useState } from "react";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import { putApis } from "../../Common/Apis";
import { BUTTONS, NOTIFICATIONS } from "../../Common/Constants";
import { providerUpdateProfileUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import { setUserDetails } from "../../Common/Utils";

const PatientViewDetails = ({
  contents,
  form,
  userDetails,
  dispatch,
  notify,
}) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    await putApis(providerUpdateProfileUrl, values)
      .then((response) => {
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        delete response?.data["message"];
        setUserDetails({ ...userDetails, ...response?.data });
        dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        notify(NOTIFICATIONS.ERROR.KEY, error?.response?.data?.message);
      });
  };

  const onFinishFailed = async () => {
    setLoading(false);
  };

  return (
    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        label={
          <Popover content={contents?.viewPatient?.audiogram} trigger="hover">
            <span className="cursor-pointer left">
              Display Audiogram <InfoCircleFilled className="ml025" />
            </span>
          </Popover>
        }
        name="showAudiogram"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        label={
          <Popover content={contents?.viewPatient?.audiogram} trigger="hover">
            <span className="cursor-pointer left">
              Display Chance of Progression{" "}
              <InfoCircleFilled className="ml025" />
            </span>
          </Popover>
        }
        name="showChanceProgression"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        label={
          <Popover content={contents?.viewPatient?.audiogram} trigger="hover">
            <span className="cursor-pointer left">
              Display Auditory Score <InfoCircleFilled className="ml025" />
            </span>
          </Popover>
        }
        name="showAuditoryScore"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading}>
          <Loader loading={loading} text={BUTTONS.SUBMIT} />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PatientViewDetails;
