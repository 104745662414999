import { Collapse, Flex, Form } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProfileDetails } from "../../../Reducers/profileSlice";
import "./style.css";
import BasicDetails from "./BasicDetails";
import PatientViewDetails from "./PatientViewDetails";
import { CaretRightOutlined } from "@ant-design/icons";
import useNotify from "../../Common/Notify";
import { useApiLoader } from "../../Common/ApiLoaderContext";
import { ROLES, SERVICES } from "../../Common/Constants";

const ProviderProfile = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { contents } = useSelector((state) => state?.remoteConfig);
  const isMobile = useSelector((state) => state?.app?.isMobile);
  const profileDetails = useSelector((state) => state.profile?.profileDetails);
  const dispatch = useDispatch();
  const defaultDoctor = useSelector((state) => state?.filter?.defaultDoctor);
  const { setShowFullLoader } = useApiLoader();
  const { notify } = useNotify();
  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.entries(profileDetails).length === 0) {
      dispatch(setProfileDetails(userDetails));
    }
    form.setFieldsValue({
      ...profileDetails,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProfileDetails = (label, value) => {
    if (value) {
      return (
        <div className="flex-start-center">
          <Flex wrap="wrap" gap="small" vertical={isMobile ? true : false}>
            <div className="bold">{label}: </div>
            <div>{Array.isArray(value) ? value.join(", ") : value}</div>
          </Flex>
        </div>
      );
    }
  };

  const list = [
    {
      key: "1",
      label: "Basic Details",
      children: (
        <BasicDetails
          profileDetails={profileDetails}
          renderProfileDetails={renderProfileDetails}
          defaultDoctor={defaultDoctor}
        />
      ),
    },
    {
      key: "2",
      label: "Patient View Details",
      children: (
        <PatientViewDetails
          userDetails={userDetails}
          profileDetails={profileDetails}
          dispatch={dispatch}
          setShowFullLoader={setShowFullLoader}
          notify={notify}
          form={form}
          contents={contents?.provider?.profile}
        />
      ),
    },
  ];

  return (
    <Collapse
      className="mt1"
      collapsible
      defaultActiveKey={["1", "2"]}
      items={
        userDetails?.programs?.includes(SERVICES.AUDIOLOGY) &&
        userDetails?.role === ROLES.PROVIDER
          ? list
          : list?.filter((item) => item?.key !== "2")
      }
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    />
  );
};

export default ProviderProfile;
