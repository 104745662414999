import { CalendarOutlined } from "@ant-design/icons";
import { Card, Col, Empty, Flex, Row, Splitter } from "antd";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Common/Constants";
import { patientDashboardImages } from "../../Common/Images";
import { isNativeIosAppOnly } from "../../Common/Utils";

const Ring = ({ details }) => {
  return (
    <Card
      id="Ring"
      title={
        <Row gutter={[16, 16]} justify={"space-between"} className="mt05">
          <Col xs={4} sm={3} md={3} lg={3} xl={3}>
            <img src={patientDashboardImages.RING} alt="health" width="100%" />
          </Col>
          <Col
            xs={17}
            sm={19}
            md={19}
            lg={19}
            xl={19}
            className="bold fs18 flex-start-center"
          >
            SAiWELL Ring
          </Col>
          <Col xs={3} sm={2} md={2} lg={2} xl={2} className="flex-center">
            <Link to={ROUTES.PATIENT.RING.PATH}>
              <img
                src={patientDashboardImages.ORANGE_VIEWMORE}
                alt="ring"
                width="100%"
              />
            </Link>
          </Col>
        </Row>
      }
      className="bdr8 box-more-shadow"
      styles={{ body: { padding: "0" }, color: "#141414" }}
    >
      {details ? (
        <Splitter>
          <Splitter.Panel collapsible={false} resizable={false}>
            <div className="pd-tlr1-b0">
              <div className="grey fs12">Calories</div>
              <Flex wrap="wrap" gap="small" className="flex-baseline">
                <div className="fs22 bold">{details?.calories}</div>
                <div className="grey fs12">kcal</div>
              </Flex>
            </div>

            <div className="mb05 mt1 text-center">
              <img
                src={patientDashboardImages.CALORY}
                alt="health"
                width="20%"
              />
            </div>
            <Row gutter={[16, 8]} justify={"end"} className="pd05">
              <Col
                xs={16}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                className="grey fs12 text-right"
              >
                <CalendarOutlined className="mr05" />
                {details?.date}
              </Col>
            </Row>
          </Splitter.Panel>
          <Splitter.Panel>
            <Splitter layout="vertical">
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pdlr1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                      <img
                        src={patientDashboardImages.STEP}
                        alt="steps"
                        width="100%"
                      />
                    </Col>
                    <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                      <div className="grey fs12">Steps</div>
                      <div className="bold fs22">{details?.step}</div>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
              <Splitter.Panel collapsible={false} resizable={false}>
                <div className="pdlr1">
                  <Row gutter={[16, 16]} className="flex-center">
                    <Col xs={8} sm={6} md={6} lg={6} xl={6}>
                      <img
                        src={patientDashboardImages.HEART_RATE}
                        alt="calories"
                        width="100%"
                      />
                    </Col>
                    <Col xs={16} sm={18} md={18} lg={18} xl={18}>
                      <div className="grey fs12">Heart Rate</div>
                      <div>
                        <Flex wrap="wrap" gap="small" className="flex-baseline">
                          <div className="fs24 bold">{details?.heartRate}</div>
                          <div className="grey fs12">bpm</div>
                        </Flex>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Splitter.Panel>
            </Splitter>
          </Splitter.Panel>
        </Splitter>
      ) : (
        <Empty
          imageStyle={{
            height: 60,
          }}
          className="pd1 fs13"
          description={
            isNativeIosAppOnly()
              ? "Connect to apple health or to SAiWELL devices to view the data."
              : "No data available"
          }
        ></Empty>
      )}
    </Card>
  );
};

export default Ring;
