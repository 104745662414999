import { Button, Col, Row } from "antd";
import Popup from "../../../Common/Popup";

const Connect = ({
  deviceConnectionDetails,
  isListModalOpen,
  handleListCancel,
  devices,
  showRingModal,
}) => {
  return (
    <Popup
      title={"Connect A Device"}
      open={isListModalOpen}
      cancel={handleListCancel}
      footer={null}
      width={600}
    >
      {devices?.map((item, index) => {
        return (
          <div
            key={index}
            className={`pd1 grey-fill box-shadow ${index > 0 ? "mt1" : ""}`}
          >
            <Row className="flex-center">
              <Col xs={16} sm={18} md={18} lg={20} xl={20}>
                <b className="mr05 blue">{item?.label}</b>
              </Col>
              <Col xs={8} sm={6} md={6} lg={4} xl={4}>
                {deviceConnectionDetails?.[
                  item?.label === "Saiwell Ring" ? "SaiwellRing" : item?.label
                ] ? (
                  <div className="green bold">Connected</div>
                ) : (
                  <Button size="small" onClick={() => showRingModal()}>
                    Connect
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        );
      })}
    </Popup>
  );
};

export default Connect;
