import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setProfileDetails } from "../Reducers/profileSlice";
import { getApis } from "./Common/Apis";
import { ROUTES, USER_TYPES, ROLES } from "./Common/Constants";
import CustomSpin from "./Common/CustomSpin";
import {
  clinicAdminProfileUrl,
  patientProfileUrl,
  providerProfileUrl,
} from "./Common/Endpoints";
import {
  setUserDetails,
  isAndroidOrIosApp,
  postMessageToMobileApp,
} from "./Common/Utils";
import TermsAndConditions from "./Patients/TermsAndConditions";
import { Flex, Layout } from "antd";
import LOGO from "../Assets/images/logo.png";
import SmallFooter from "./Common/Layouts/Footers/Small";
import TermsOfUseModal from "./Common/Sessions/TermsOfUseModal";
const { Header } = Layout;

const Landing = () => {
  const { programs } = useSelector((state) => state?.remoteConfig);
  const isLoggedIn = !!localStorage.getItem("authToken");
  const userType =
    useSelector((state) => state?.app?.userType) ||
    localStorage.getItem("userType");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const clinicAdminMenus = JSON.parse(localStorage.getItem("clinicAdminMenus"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromRing = searchParams.get("fromRing");
  const clinicFromBookings = searchParams.get("clinicFromBookings");
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isTermsDocOpen, setIsTermsDocOpen] = useState(false);
  const isMobileApp = isAndroidOrIosApp();

  const patientRedirectPage = (data) => {
    if (fromRing) {
      navigate(ROUTES.PATIENT.DEVICES.PATH);
    } else if (clinicFromBookings) {
      navigate(
        `${ROUTES.PATIENT.SLEEP_STUDY.PATH}?clinic=${clinicFromBookings}`
      );
    } else {
      if (data?.length > 0) {
        navigate(ROUTES.PATIENT.DASHBOARD.PATH);
      } else {
        navigate(ROUTES.PATIENT.PROFILE.PATH);
      }
    }
  };

  const getPatientProfileData = async () => {
    if (!userDetails?.firstName) {
      await getApis(patientProfileUrl)
        .then((response) => {
          setUserDetails({ ...userDetails, ...response?.data });
          dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
          if (response?.data?.clinics?.includes("SFOTO")) {
            postMessageToMobileApp({ showBookingsLink: true }, "showMenu");
          } else {
            postMessageToMobileApp({ showBookingsLink: false }, "showMenu");
          }
          if (!response?.data?.termsAndConditions) {
            setIsTermsModalOpen(true);
          } else {
            patientRedirectPage(response?.data?.dashboard);
          }
        })
        .catch(() => {});
    } else {
      if (userDetails?.clinics?.includes("SFOTO")) {
        postMessageToMobileApp({ showBookingsLink: true }, "showMenu");
      } else {
        postMessageToMobileApp({ showBookingsLink: false }, "showMenu");
      }
      if (!userDetails?.termsAndConditions) {
        setIsTermsModalOpen(true);
      } else {
        patientRedirectPage(userDetails?.dashboard);
      }
    }
  };

  const getProviderProfileDetails = async () => {
    if (!userDetails?.firstName) {
      await getApis(providerProfileUrl)
        .then((response) => {
          setUserDetails({ ...userDetails, ...response?.data });
          dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
          navigate(ROUTES.PROVIDER.DASHBOARD.PATH);
        })
        .catch(() => {});
    } else {
      navigate(ROUTES.PROVIDER.DASHBOARD.PATH);
    }
  };

  const getClinicAdminProfileDetails = async () => {
    if (!userDetails?.firstName) {
      await getApis(clinicAdminProfileUrl)
        .then((response) => {
          setUserDetails({ ...userDetails, ...response?.data });
          dispatch(setProfileDetails({ ...userDetails, ...response?.data }));
          if (!clinicAdminMenus) {
            const data = programs?.filter(
              (item) => item?.clinic?.value === response?.data?.clinic
            );
            localStorage.setItem(
              "clinicAdminMenus",
              JSON.stringify(data?.[0]?.services)
            );
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {});
    } else {
      navigate(
        userDetails?.role === ROLES.SLEEP_STAFF
          ? ROUTES.CLINIC_ADMIN.SLEEP_LOCATIONS.PATH
          : ROUTES.CLINIC_ADMIN.DASHBOARD.PATH
      );
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (userType === USER_TYPES.PATIENT) {
        getPatientProfileData();
      } else if (userType === USER_TYPES.PROVIDER) {
        getProviderProfileDetails();
      } else if (userType === USER_TYPES.CLINIC_ADMIN) {
        getClinicAdminProfileDetails();
      }
    } else {
      isMobileApp
        ? navigate(ROUTES.PATIENT.LOGIN.PATHS.SEND_OTP)
        : navigate(ROUTES.DEFAULT.PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTermsDocCancel = () => {
    setIsTermsDocOpen(false);
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {!isMobileApp && (
        <Header className="fixed-header">
          <Flex
            justify="space-between"
            wrap="wrap"
            gap="small"
            className="align-center"
          >
            <Link to={ROUTES.DEFAULT.PATH} className="flex-center">
              <img src={LOGO} alt="Logo" width="140" />
            </Link>
          </Flex>
        </Header>
      )}
      <Layout style={{ backgroundColor: "#eee" }}>
        <div className="flex-center">
          {isTermsDocOpen && (
            <TermsOfUseModal
              isTermsModalOpen={isTermsModalOpen}
              handleTermsModalCancel={handleTermsDocCancel}
            />
          )}
          {isTermsModalOpen ? (
            <TermsAndConditions
              isTermsModalOpen={isTermsModalOpen}
              userDetails={userDetails}
              setIsTermsDocOpen={setIsTermsDocOpen}
            />
          ) : (
            <CustomSpin color={"#fff"} />
          )}
        </div>
      </Layout>
      <SmallFooter />
    </Layout>
  );
};

export default Landing;
