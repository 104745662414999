import { Button, Card, Form, Input } from "antd";
import { useState } from "react";
import { postApis } from "../../Common/Apis";
import { BUTTONS, NOTIFICATIONS, VALIDATIONS } from "../../Common/Constants";
import { clinicAdminProfileMetaUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import useNotify from "../../Common/Notify";

const SupportEmail = ({ metaDetails }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { notify } = useNotify();

  const onFinish = async (values) => {
    setLoading(true);
    await postApis(clinicAdminProfileMetaUrl, values)
      .then((response) => {
        setLoading(false);
        notify(NOTIFICATIONS.SUCCESS.KEY, response?.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  return (
    <Card
      title={"Support Email"}
      className="mt1"
      extra={[
        <Button key={"edit"} type="primary" onClick={() => setShowForm(true)}>
          Edit
        </Button>,
      ]}
    >
      {showForm ? (
        <Form
          initialValues={{ supportEmail: metaDetails?.supportEmail }}
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          validateMessages={{
            required: VALIDATIONS.DEFAULT.BLANK,
          }}
          className="pd1"
        >
          <Form.Item
            label={false}
            name="supportEmail"
            size="large"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Button
            type="primary"
            className="mt2"
            htmlType="submit"
            block
            disabled={loading}
          >
            <Loader loading={loading} text={BUTTONS.SUBMIT} />
          </Button>
        </Form>
      ) : (
        <div className="grey-fill pd05">
          <div>
            <span className="grey">Email:</span>
            <span className="black ml05">{metaDetails?.supportEmail}</span>
          </div>
        </div>
      )}
    </Card>
  );
};

export default SupportEmail;
