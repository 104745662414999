import { LinkOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Flex, QRCode, Row } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getApis } from "../../Common/Apis";
import { providerViewPatientAudiologyPaymentsUrl } from "../../Common/Endpoints";
import Loader from "../../Common/Loader";
import Popup from "../../Common/Popup";

const Products = ({ audiologyDetails, profileDetails, productDetails }) => {
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [url, setUrl] = useState();
  const [paymentLinkModalOpen, setPaymentLinkModalOpen] = useState(false);

  const getPaymentLinkHandler = async (record) => {
    setSelectedRow(record);
    setLoading(true);
    await getApis(providerViewPatientAudiologyPaymentsUrl, {
      cancelUrl: window.location.href,
      successUrl: window.location.href,
      token: profileDetails?.token,
      ...record,
    })
      .then((response) => {
        setLoading(false);
        setUrl(response?.data?.paymentUrl);
        showPaymentLinkModal();
      })
      .catch(() => setLoading(false));
  };

  const showPaymentLinkModal = () => {
    setPaymentLinkModalOpen(true);
  };

  const cancelPaymentLinkModal = () => {
    setPaymentLinkModalOpen(false);
  };

  return audiologyDetails ? (
    <div>
      {paymentLinkModalOpen && (
        <Popup
          title={"Pay"}
          open={paymentLinkModalOpen}
          cancel={cancelPaymentLinkModal}
          footer={null}
          width={500}
          style={{ top: "25px" }}
        >
          <div className="text-center">
            <div className="bold">Scan Here</div>
            <div className="flex-center mt1">
              <QRCode value={url} size={140} />
            </div>
            <div className="mt05">OR</div>

            <Button
              type="primary"
              className="mt05"
              onClick={() => window.location.assign(url)}
            >
              Pay Here
            </Button>
          </div>
        </Popup>
      )}
      <div className="pd1 text-center">
        You are viewing this page because this service is enabled in the admin
        settings.
      </div>

      <Row gutter={[16, 16]} className="mt2">
        {productDetails?.map((item, index) => {
          return (
            <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card
                title={item?.product}
                className="box-shadow"
                actions={[
                  <Link
                    key="generate"
                    onClick={() => getPaymentLinkHandler(item)}
                    disabled={selectedRow?.id === item.id && loading}
                  >
                    <LinkOutlined className="mr05" />
                    <Loader
                      loading={selectedRow?.id === item.id && loading}
                      text={"Generate Link"}
                    />
                  </Link>,
                ]}
              >
                {item?.subscriptionAmount && (
                  <Flex className="mt05">
                    <span className="mr05 grey">Subscription Amount:</span>
                    ${item?.subscriptionAmount}
                  </Flex>
                )}
                {item?.oneTimeAmount && (
                  <Flex className="mt05">
                    <span className="mr05 grey">One Time Amount:</span>
                    ${item?.oneTimeAmount}
                  </Flex>
                )}
                {item?.durationInMonth && (
                  <Flex className="mt05">
                    <span className="mr05 grey">Duration In Months:</span>
                    {item?.durationInMonth}
                  </Flex>
                )}
                {item?.lifetimeSubscription && (
                  <Flex className="mt05">
                    <span className="mr05 grey">Life Time Subscription:</span>
                    {item?.lifetimeSubscription ? "Yes" : "No"}
                  </Flex>
                )}
                {item?.paymentType && (
                  <Flex className="mt05">
                    <span className="mr05 grey">Payment Type:</span>
                    {item?.paymentType}
                  </Flex>
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  ) : (
    <Empty
      description="Payment will be accessible once the insights have been generated."
      imageStyle={{
        height: 70,
      }}
    />
  );
};

export default Products;
