import { Col, Collapse, Row, Card } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { YoutubeFilled } from "@ant-design/icons";
const { Meta } = Card;

const Devices = ({ searchTerm, content }) => {
  const [selectedKey, setSelectedKey] = useState("1");

  const filteredItems = content?.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOnClick = (e) => {
    setSelectedKey(e.key);
  };

  const renderCard = (item) => {
    return (
      <Row gutter={[16, 16]} justify="space-between" data-testid="devices">
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Link to={item?.link} target={"_blank"}>
            <Card
              styles={{ body: { background: "#f8f8f8" } }}
              cover={<img alt={item?.key} src={item?.image} />}
              className="box-shadow"
            >
              <Meta
                title={
                  <div className="left">
                    <div className="flex-center">
                      <YoutubeFilled
                        style={{ fontSize: "28px", color: "red" }}
                        className="mr05"
                      />
                      {item?.title}
                    </div>
                  </div>
                }
                description={item?.metaDesc}
              />
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} className="flex-center">
          <div dangerouslySetInnerHTML={{ __html: item?.description }} />
        </Col>
      </Row>
    );
  };

  return (
    <Row className="mt1">
      <Col span={24}>
        {filteredItems?.map((item, index) => {
          return (
            <Collapse
              key={index}
              size="large"
              expandIconPosition="end"
              items={[
                {
                  key: item?.key,
                  label: item?.label,
                  children: renderCard(item),
                },
              ]}
              defaultActiveKey={[selectedKey]}
              onClick={handleOnClick}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default Devices;
