import { Button, Checkbox, Col, Divider, Modal, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApis, postApis } from "../../../Common/Apis";
import { BUTTONS, ROUTES, USER_TYPES } from "../../../Common/Constants";
import {
  patientPaymentAutoChargeUrl,
  patientPaymentChargeUrl,
  patientPaymentClinicCardsUrl,
} from "../../../Common/Endpoints";
import Loader from "../../../Common/Loader";
import {
  getBaseUrl,
  getOS,
  getPageHeader,
  logAnalyticsEvent,
} from "../../../Common/Utils";
import VISA from "../../../..//Assets/images/visa.svg";
import MASTER from "../../../..//Assets/images/master.svg";
import { setBookingDetails } from "../../../../Reducers/bookingsSlice";

const Pay = ({ currentStep, setCurrentStep, clinic, userDetails }) => {
  const { payments } = useSelector((state) => state?.remoteConfig);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState();
  const [selectedCard, setSelectedCard] = useState();
  const bookingDetails =
    useSelector((state) => state?.bookings?.details) ||
    JSON.parse(localStorage.getItem("bookingDetails"));
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const { confirm } = Modal;

  const getCards = async () => {
    setShowLoader(true);
    await getApis(patientPaymentClinicCardsUrl, {
      clinic: clinic,
    })
      .then((response) => {
        setShowLoader(false);
        setCards(response?.data);
      })
      .catch(() => setShowLoader(false));
  };

  useEffect(() => {
    logAnalyticsEvent("signed", {
      userType: USER_TYPES.PATIENT,
      data: userDetails?.phoneNumber,
      os: getOS(),
    });

    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCardChange = (e) => {
    const value = e.target.value;
    setSelectedCard((prevSelectedCard) =>
      prevSelectedCard === value ? null : value
    );
  };

  const showAutoChargeConfirm = () => {
    const card = cards?.filter((item) => item?.id === selectedCard)?.[0];
    confirm({
      title: "Are you sure?",
      content: `You will be automatically charged $${
        360 * parseInt(bookingDetails?.days)
      } from the card ending with ...${card?.last4}`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        autoChargeApi();
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const withoutCardApi = async () => {
    const params = {
      clinic: clinic,
      mode: bookingDetails?.paymentStatus === "Self Pay" ? "payment" : "setup",
      type: bookingDetails?.type,
      cancelUrl: `${getBaseUrl()}${
        ROUTES.PATIENT.SLEEP_STUDY.PATH
      }?clinic=${clinic}&step=3`,
      successUrl: `${getBaseUrl()}${
        ROUTES.PATIENT.SLEEP_STUDY.PATH
      }?clinic=${clinic}&step=4&`,
    };

    const newData = {
      ...bookingDetails,
      stripePaymentStatus:
        bookingDetails?.paymentStatus === "Self Pay" ? "paid" : "NA",
    };

    await getApis(patientPaymentChargeUrl, params)
      .then((response) => {
        setLoading(false);
        dispatch(setBookingDetails(newData));
        localStorage.setItem("bookingDetails", JSON.stringify(newData));
        if (response?.data?.paymentUrl) {
          window.location.assign(response?.data?.paymentUrl);
        } else {
          window.location.assign(ROUTES.WENT_WRONG.PATH);
        }
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          setBookingDetails({
            ...bookingDetails,
            stripePaymentStatus: "failed",
          })
        );
        localStorage.setItem(
          "bookingDetails",
          JSON.stringify({
            ...bookingDetails,
            stripePaymentStatus: "failed",
          })
        );
      });
  };

  const autoChargeApi = async () => {
    const params = {
      clinic: clinic,
      cardId: selectedCard,
      type: bookingDetails?.type,
    };

    const newData = {
      ...bookingDetails,
      cardId: selectedCard,
      stripePaymentStatus: "paid",
    };

    await postApis(patientPaymentAutoChargeUrl, params)
      .then(() => {
        setLoading(false);
        dispatch(setBookingDetails(newData));
        localStorage.setItem("bookingDetails", JSON.stringify(newData));
        setCurrentStep(currentStep + 1);
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          setBookingDetails({
            ...bookingDetails,
            cardId: selectedCard,
            stripePaymentStatus: "failed",
          })
        );
        localStorage.setItem(
          "bookingDetails",
          JSON.stringify({
            ...bookingDetails,
            cardId: selectedCard,
            stripePaymentStatus: "failed",
          })
        );
      });
  };

  const onSubmitHandler = async () => {
    setLoading(true);
    if (selectedCard) {
      if (bookingDetails?.paymentStatus === "Self Pay") {
        showAutoChargeConfirm();
      } else {
        setLoading(false);
        dispatch(
          setBookingDetails({
            ...bookingDetails,
            cardId: selectedCard,
            stripePaymentStatus: "NA",
          })
        );
        setCurrentStep(currentStep + 1);
      }
    } else {
      withoutCardApi();
    }
  };

  return (
    <Row data-testid="bookings-pay" justify={"center"} className="mt2">
      <Col xs={24} sm={24} md={24} lg={18} xl={16}>
        <div>
          <div className="bold fs18 mt1">Instructions:</div>
          <ul>
            {payments?.[bookingDetails?.paymentStatus]?.instructions?.map(
              (item, index) => {
                return <li key={index}>{item}</li>;
              }
            )}
          </ul>
        </div>

        {showLoader && <Spin size="large" className="mt3 flex-center" />}

        <Row gutter={[16, 16]}>
          {cards?.map((item, index) => {
            return (
              <Col key={index} xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="mt05 card">
                  <Checkbox
                    value={item?.id}
                    checked={selectedCard === item?.id}
                    onChange={onCardChange}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={6} className="flex-center">
                        <img
                          src={
                            item?.display_brand === "mastercard" ? MASTER : VISA
                          }
                          width="100%"
                          alt={item?.display_brand}
                        />
                      </Col>
                      <Col span={18} className="flex-start-center">
                        {getPageHeader(item?.display_brand)} ending in{" "}
                        {item?.last4}
                      </Col>
                    </Row>
                  </Checkbox>
                </div>
              </Col>
            );
          })}
        </Row>

        <Divider style={{ margin: "2rem 0 1.5rem 0" }} />
        <Row gutter={[16, 16]} justify={"center"} className="mt2">
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={
                !payments?.[bookingDetails?.paymentStatus]?.pay || loading
              }
              onClick={onSubmitHandler}
            >
              <Loader
                loading={loading}
                text={
                  bookingDetails?.paymentStatus === "Self Pay"
                    ? BUTTONS.PAY
                    : BUTTONS.NEXT
                }
              />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Pay;
